import React from "react";
import { StandardButton, TransitionCard } from "..";
import { ButtonContainer } from "./Introduction.style";

interface Props {
  duration?: string;
  title: string;
  description: string;
  buttonText: string;
  bgPicture?: string;
  onClickStart: () => void;
}

const Introduction: React.FC<Props> = (props) => {
  return (
    <TransitionCard bgPicture={props.bgPicture || ""}>
      {props.duration && (
        <p className="duration">
          <img src={"./img/icon-time.svg"} /> {props.duration}
        </p>
      )}
      <h2 className="activity-title">{props.title}</h2>
      <div className="activity-description" dangerouslySetInnerHTML={{ __html: props.description }} />
      <ButtonContainer>
        <StandardButton onClick={props.onClickStart} className="btn-start" textButton={props.buttonText} />
      </ButtonContainer>
    </TransitionCard>
  );
};

export default Introduction;
