import { styled } from "../../theme";
export const HeaderContainer = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 100%;
  height: ${(props) => props.theme.headerHeight.desktop};
  background-color: ${(props) => props.theme.color.headerBackgroundColor};

  @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    height: 21vh;
  }
`;

export const HeaderItemContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 33vw;
`;

export const HeaderNavigationContainer = styled.div`
  display: flex;
  max-width: 100%;
  box-sizing: border-box;
  height: 40%;
`;

export const HeaderNavbarContainer = styled.div`
  width: 50%;
  display: flex;
  box-sizing: border-box;
  height: 100%;
`;

export const HeaderBannerContainer = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  width: min(1200px, 80%);
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  span {
    display: block;
  }

  @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    width: 90%;
    flex-direction: column;
  }
`;

export const CenterItem = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
`;

interface LogoProps {
  logo: string;
}

interface DecorationProps {
  decoration: string;
}

export const HeaderLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 30%;
  box-sizing: border-box;
  padding: 0.5em;

  @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    /* display: none; */
    width: 100%;
  }
`;
export const HeaderDecorationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 12%;
  height: 100%;
  box-sizing: border-box;

  &.empty {
    visibility: hidden;
  }

  @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    display: none;
  }
`;

export const Logo = styled.div<LogoProps>`
  align-self: flex-start;
  background: ${(props) => `url(${props.logo})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left;
  height: 100%;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    background-position: center;
  }
`;
export const Decoration = styled.div<DecorationProps>`
  align-self: flex-end;
  background: ${(props) => `url(${props.decoration})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
`;

export const Title = styled.div`
  text-align: center;
  box-sizing: border-box;
  font-weight: normal;
  font-size: x-large;
  color: ${(props) => props.theme.color.text};
  white-space: pre-line;
`;

export const ProductLogo = styled.img`
  width: auto;
  height: 4em;
`;

export const HeaderMainTitleStyle = styled.div`
  display: flex;
  /* text-transform: uppercase; */
  flex-direction: column;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 3.4em;
  font-family: ${(props) => props.theme.font.styledFont};
  color: ${(props) => props.theme.color.mainTitleColor};
  text-align: center;
  padding: 0.1em;
  span {
    line-height: 0.8em;
    display: block;
    @media (max-width: ${(props) => props.theme.responsive.mobile}) {
      display: initial;
      &:not(:last-of-type)::after {
        content: " ";
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.notebook}) {
    font-size: 2.6em;
  }

  @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    font-size: 1.6em;
    max-height: inherit;
    max-width: inherit;
    width: 100%;
  }
`;

export const SeparatorLine = styled.div`
  /*border-bottom: dashed white 0.1em;
  color: white;
  align-self: center;
  width: 20%;*/
`;

export const HeaderSubTitleStyle = styled.div`
  /*  padding: 4em;
  width: 70%;
  min-width: 4em;
  max-width: 70%;
  color: white;
  box-sizing: border-box;
  text-align: center;
  padding: 1em;

  @media (min-width: 1475px) {
    font-size: 2em;
  }

  @media (max-width: 1475px) {
    font-size: 1.5em;
  }

  @media (max-width: 400px) {
    font-size: 1em;
    white-space: nowrap;
    white-space: wrap;
    max-height: 3em;
    max-width: 70%;
  } */
`;
