import { styled } from "../../theme";

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: right;
  background-color: ${(props) => props.theme.color.footerBackgroundColor};
  box-sizing: border-box;
  height: ${(props) => props.theme.footerHeight.desktop};

  @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    justify-content: center;
    height: inherit;
    flex-direction: column-reverse;
  }
`;

export const FooterItemContainer = styled.div`
  box-sizing: border-box;
  min-width: 15%;
  display: flex;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    padding: 0.5em;
  }
`;
export const FooterLink = styled.a`
  width: 100%;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  display: block;
  color: ${(props) => props.theme.color.text};
`;

export const FooterItemContent = styled.button`
  width: 100%;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  color: ${(props) => props.theme.color.text};
  text-decoration: underline;
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
`;

export const ContainerInModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
