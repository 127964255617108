import styled from "styled-components";

interface LogoCardProps {
  logoUrl: string;
}

export const LogoCardStyle = styled.div<LogoCardProps>`
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.logoUrl})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
