import React from "react";

const IconResources: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 383.03 383.88">
      <g fill={props.fill} id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
          <path d="M180.4,0h23.2c1.21.23,2.42.48,3.63.67,10.32,1.68,20.83,2.57,30.92,5.15C332.47,30,392.75,117.21,381.74,214.19c-5.55,48.88-27.06,90.24-64.62,121.91C261.83,382.71,198.45,396,130,372.52,59.76,348.44,18,297.72,3,225c-1.44-7-2-14.26-3-21.4V179.65c1-7,1.74-14.06,2.93-21C16.24,81,77.08,18.67,154.19,3.78,162.84,2.1,171.66,1.24,180.4,0Zm11.1,311.39h89.42c18.56,0,30.46-11.85,30.47-30.34q0-69,0-138.06c0-18.73-11.86-30.51-30.68-30.52-23.57,0-47.15,0-70.72.06-2.5,0-3.78-.79-4.83-3-3.38-7.08-7-14.06-10.47-21.08-5.57-11.13-14.62-16.6-27-16.59q-32.92,0-65.85,0c-18,0-29.94,12-29.95,30.09q0,89.61,0,179.22c0,18.28,11.94,30.2,30.22,30.21Z" />
          <path d="M295.49,183.86v4.25q0,46.21,0,92.41c0,10.2-4.7,15-14.79,15H102.62c-10.13,0-14.85-4.73-14.85-14.9q0-46.39,0-92.78v-3.94Z" />
          <path d="M295.49,167.46H87.77v-3.54q0-30.87,0-61.73,0-14.41,14.57-14.42c21.44,0,42.89.08,64.34,0,6.74,0,11.35,2.61,14.26,8.75,4.11,8.66,8.68,17.1,12.75,25.78,2.15,4.57,5.42,6.37,10.34,6.35,25.68-.08,51.37,0,77.06,0,9.78,0,14.37,4.63,14.39,14.48C295.5,151.11,295.49,159.18,295.49,167.46Z" />
        </g>
      </g>
    </svg>
  );
};

export default IconResources;
