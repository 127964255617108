import React, { useState } from "react";
import { PodcastPlayer } from "../../components";
import { SectionPodcast } from "../../types";
import { EnrichedProps } from "../../components/SectionWrapper/SectionWrapper";
import HeaderAndFooter from "../HeaderAndFooter/HeaderAndFooter.template";
import BaseContentSection from "../BaseContentSection/BaseContentSection";

const PodcastPage: React.FC<EnrichedProps> = (props) => {
  const section = props.content as SectionPodcast;
  const completeOnProgressCriteria = section.progressOnPercentForCompletion || 0;
  const [durationInMins, setDurationInMins] = useState(0);

  const completeOnProgressMeasure = async (progressMeasure: number) => {
    if (progressMeasure < completeOnProgressCriteria) {
      return;
    }

    return props.completeSection();
  };

  const onDurationChange = (durationInSecs: number) => {
    setDurationInMins(Math.ceil(durationInSecs / 60));
  };

  return (
    <HeaderAndFooter>
      <BaseContentSection {...props} duration={`${durationInMins} min`}>
        <PodcastPlayer
          src={section.audio}
          onProgressMeasureChange={completeOnProgressMeasure}
          onDurationChange={onDurationChange}
        />
      </BaseContentSection>
    </HeaderAndFooter>
  );
};

export default PodcastPage;
