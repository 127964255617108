import React from "react";
import {
  SuccessImageContainer,
  SuccessMainContainer,
  SuccessText,
  SuccessTitleContainer,
} from "./SuccessContainer.style";

interface Props {
  title: string;
  image: string;
  description: string;
}

const SuccessContainer: React.FC<Props> = (props) => {
  return (
    <SuccessMainContainer className="success-main-container">
      <SuccessTitleContainer className="success-title-container">
        <h2 className="success-title">{props.title}</h2>
      </SuccessTitleContainer>
      <SuccessImageContainer className="success-image container">
        <img className="success-image" src={props.image} alt="visu" />
      </SuccessImageContainer>
      <SuccessText className="success-text standard-text-size">{props.description}</SuccessText>
    </SuccessMainContainer>
  );
};

export default SuccessContainer;
