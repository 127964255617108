import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StandardButton } from "../../components";
import { EnrichedProps } from "../../components/SectionWrapper/SectionWrapper";
import QuizBreadcrumb from "../../components/QuizBreadcrumb/QuizBreadcrumb";
import {
  MediaSection,
  SectionFinalQuiz,
  SectionPdf,
  SectionPodcast,
  SectionResources,
  SectionVideo,
} from "../../types";
import {
  ChildrenContainer,
  DurationText,
  FlexColWithMargin,
  FlexContainer,
  Row,
  SectionIconContainer,
  StyledParagraph,
} from "./BaseContentSection.style";
import { useWindowSize } from "../../hooks";
import Icon from "../../icons";

const SECTION_TYPE_CONTENT_MAPPER: {
  [Property in MediaSection]: { label: string; icon: React.SVGProps<SVGSVGElement> };
} = {
  videoPage: { label: "VIDÉO", icon: <Icon name="video" /> },
  podcastPage: { label: "PODCAST", icon: <Icon name="podcast" /> },
  pdfPage: { label: "PDF", icon: <Icon name="pdf" /> },
  richTextPage: { label: "INFORMATION", icon: <Icon name="info" /> },
  resourcePage: { label: "KIT OPÉRATIONEL", icon: <Icon name="resource" /> },
  finalQuiz: { label: "QUIZ FINAL", icon: <Icon name="caseStudy" /> },
};

interface Props extends EnrichedProps {
  hideBackground?: boolean;
  duration?: string;
  hideNavigationButtons?: boolean;
}

const BaseContentSection: React.FC<Props> = (props) => {
  const section = props.content as SectionVideo | SectionPdf | SectionPodcast | SectionFinalQuiz | SectionResources;
  const duration = props.duration || section.duration || "";
  const windowSize = useWindowSize();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [mediaHeight, setMediaHeight] = useState("");
  const buttonsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clientWidth = ref?.current?.clientWidth || 1;
    const newHeigth = (clientWidth * 9) / 16;
    ref && setMediaHeight(`${newHeigth}px`);
  }, [windowSize]);

  useEffect(() => {
    window.scrollTo({ left: 0, top: buttonsRef?.current?.offsetTop, behavior: "smooth" });
  }, [props.isSectionCompleted]);
  return (
    <FlexColWithMargin className="base-section-container">
      <Row className="base-section-header">
        <FlexContainer>
          <SectionIconContainer>{SECTION_TYPE_CONTENT_MAPPER[section.type].icon}</SectionIconContainer>

          <FlexContainer className="section-description-container">
            <StyledParagraph className="base-section-description">
              <strong>{SECTION_TYPE_CONTENT_MAPPER[section.type].label} </strong>
              {duration && (
                <DurationText className="base-section-duration">
                  <img src={"./img/icon-time.svg"} /> {duration}
                </DurationText>
              )}
            </StyledParagraph>
            <StyledParagraph className="base-section-description">{section.title}</StyledParagraph>
          </FlexContainer>
        </FlexContainer>
        <QuizBreadcrumb currentQuestionIndex={props.currentIndex} questionsLenght={props.sectionCount} />
      </Row>
      <Row className="base-section-media">
        <ChildrenContainer
          className="base-section-content"
          ref={ref}
          mediaHeight={section.type !== "finalQuiz" && section.type !== "resourcePage" ? mediaHeight : "100%"}
          bg={props.hideBackground ? "" : section.image || ""}
        >
          {props.children}
        </ChildrenContainer>
      </Row>
      <Row ref={buttonsRef} className="base-section-buttons" hide={props.hideNavigationButtons}>
        {props.isSectionCompleted && (
          <StandardButton
            onClick={props.onNext}
            className="btn-landing btn-landing-next"
            textButton={props.getNextButtonName()}
          />
        )}
      </Row>
    </FlexColWithMargin>
  );
};

export default BaseContentSection;
