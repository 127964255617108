import { styled } from "../../theme";

interface CorrectionItemProps {
  isValid: boolean;
}
export const CorrectionItemContainer = styled.div<CorrectionItemProps>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-style: solid;
  border-color: ${(props) => (props.isValid ? props.theme.color.valid : props.theme.color.invalid)};
  border-width: 2px;
  padding: 1em;
  width: 30%;
  box-shadow: 0px 0px 9px 2px ${(props) => (props.isValid ? props.theme.color.valid : props.theme.color.invalid)}88;
  max-width: 90%;
  height: auto;
  border-radius: 1em;
  transition: all 0.2s ease-in-out;
  margin: 0px 2em 2em 2em;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    width: initial;
  }
`;

export const CorrectionItemTitle = styled.div<CorrectionItemProps>`
  color: ${(props) => (props.isValid ? props.theme.color.valid : props.theme.color.invalid)};
  font-weight: bold;
  font-size: large;
  padding-bottom: 0.5em;
  border-bottom: solid;
  border-bottom-width: 2px;
  border-bottom-color: ${(props) => props.theme.color.lightText};
`;

export const CorrectionItemSubtitle = styled.div`
  color: ${(props) => props.theme.color.text};
  margin-top: 1em;
`;

export const CorrectionItemAnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemAnwer = styled.div``;
