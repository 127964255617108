import React, { useState } from "react";
import { VideoPlayer } from "../../components";
import HeaderAndFooter from "../HeaderAndFooter/HeaderAndFooter.template";
import { SectionVideo } from "../../types";
import { EnrichedProps } from "../../components/SectionWrapper/SectionWrapper";
import BaseContentSection from "../BaseContentSection/BaseContentSection";

interface Props extends EnrichedProps {
  idSection: string;
}

const VideoPage: React.FC<Props> = (props) => {
  const section = props.content as SectionVideo;
  const completeOnProgressCriteria = section.progressOnPercentForCompletion || 0;
  const [shouldHidePoster, setShouldHidePoster] = useState(false);
  const [durationInMins, setDurationInMins] = useState(0);

  const completeOnProgressMeasure = async (progressMeasure: number) => {
    if (progressMeasure < completeOnProgressCriteria) {
      return;
    }

    return props.completeSection();
  };

  const onPlay = () => {
    setShouldHidePoster(true);
  };

  const onPause = () => {
    setShouldHidePoster(false);
  };

  const onDurationChange = (durationInSecs: number) => {
    setDurationInMins(Math.ceil(durationInSecs / 60));
  };
  return (
    <HeaderAndFooter>
      <BaseContentSection {...props} hideBackground={shouldHidePoster} duration={`environ ${durationInMins} min`}>
        <VideoPlayer
          video={section.video.url}
          subtitle={section.video.subtitle}
          onProgressMeasureChange={completeOnProgressMeasure}
          onDurationChange={onDurationChange}
          onPlay={onPlay}
          onPause={onPause}
        />
      </BaseContentSection>
    </HeaderAndFooter>
  );
};

export default VideoPage;
