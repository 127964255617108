import React from "react";
import {
  LandingPageContainer,
  MainContainer,
  Title,
  DashedLineSeparator,
  Subtitle,
  Illustration,
  LandingPageImageContainer,
  Description,
  TimePill,
  TimeTitle,
  TitleContainer,
  TitleWrapper,
  LogoLandingPageContainer,
} from "./Landing.style";
import { StandardButton } from "../../components";
import Store from "../../state/store";
import { inject, observer } from "mobx-react";
import Actions from "../../state/actions";
import Icon from "../../icons";
import { EnrichedProps } from "../../components/SectionWrapper/SectionWrapper";
import HeaderAndFooter from "../HeaderAndFooter/HeaderAndFooter.template";
import { useTranslation } from "react-i18next";
import { spanify } from "../../hooks";

interface Props extends EnrichedProps {
  store?: Store;
  mutator?: Actions;
  idSection: string;
}
const LandingPage: React.FC<Props> = (props) => {
  const { content } = props.store!;
  const { t } = useTranslation();

  const startModule = async () => {
    await props.completeSection();
    props.onNext();
  };
  const section = props.content;
  //console.log(content);
  return (
    <HeaderAndFooter hideHeader={true}>
      <MainContainer
        id={`section-${section.id}`}
        className="main-intro-container"
        desktop={content.branding.mainIllustration}
        mobile={content.branding.mainIllustrationMobile}
      >
        <LandingPageContainer className="intro-wrapper">
          {props.store?.content.branding.logo && (
            <LogoLandingPageContainer>
              {" "}
              <img src={props.store?.content.branding.logo} alt="" />
            </LogoLandingPageContainer>
          )}
          {(content.branding.title || content.branding.subtitle) && (
            <TitleWrapper className="intro-title-container">
              <div style={{ width: "15%" }} />
              <TitleContainer className="intro-middle-title">
                <Title className="intro-main-title">{spanify(content.branding.title)}</Title>
                {content.branding.subtitle && (
                  <React.Fragment>
                    <DashedLineSeparator className="intro-title-separator" />
                    <Subtitle className="intro-subtitle">{content.branding.subtitle}</Subtitle>
                  </React.Fragment>
                )}
              </TitleContainer>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  justifyContent: "end",
                  visibility: content.totalTime ? "visible" : "hidden",
                }}
              >
                <TimePill className="intro-time-pill-container">
                  <Icon className="intro-time-pill-icon" name="timer" fill="white" width="2em" height="2em" />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <TimeTitle>{content.totalTime?.split(" ")[0]}</TimeTitle>
                    <span style={{ fontWeight: "bold" }}>{content.totalTime?.split(" ")[1]}</span>
                  </div>
                </TimePill>
              </div>
            </TitleWrapper>
          )}
          {section.description && <Description className="intro-definition-text">{section.description}</Description>}
          <StandardButton
            onClick={startModule}
            className="btn-landing"
            textButton={props.isLast ? t("common:backToWelcomePage") : t("common:startModule")}
          />
         {/*  {content.branding.mainIllustration && (
            <LandingPageImageContainer className="intro-image-container">
              <Illustration
                className="intro-image"
                image={content.branding.mainIllustration}
                imageMobile={
                  content.branding.mainIllustrationMobile
                    ? content.branding.mainIllustrationMobile
                    : content.branding.mainIllustration
                }
              />
            </LandingPageImageContainer>
          )} */}
        </LandingPageContainer>
      </MainContainer>
    </HeaderAndFooter>
  );
};

export default inject("store", "mutator")(observer(LandingPage));
