import { styled } from "../../theme";

export const CardContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.6), 0 1px 2px rgba(0, 0, 0, 0.12);
  height: 8em;
  display: flex;
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  justify-content: space-evenly;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 1em;
  align-items: left;
  flex-direction: left;
  width: 70%;
  color: ${(props) => props.theme.color.text};
  text-align: left;
`;

interface ImageContainerProps {
  imageCard: string;
}

export const ImageCard = styled.div<ImageContainerProps>`
  background-image: ${(props) => `url(${props.imageCard})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
`;

export const ImageContainer = styled.div`
  background: red;

  @media (min-width: 1475px) {
    width: 20%;
  }

  @media (max-width: 1475px) {
    width: 60%;
  }
`;

export const TextContent = styled.div`
  height: 90%;
  white-space: wrap;
  overflow: hidden;
  text-overflow: clip;
`;

export const TitleContent = styled.div`
  font-weight: bold;
  font-size: 1.5em;
`;
