import { styled } from "../../theme";

const boxWidth = "14em";

export const ResourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.2);
  width: ${boxWidth};
  max-width: 45%;
  height: auto;
  border-radius: 1em;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: 1em;
  &:hover {
    transform: scale(1.025);
  }
`;

interface ImageContainerProps {
  image: string;
  seen?: boolean;
}
export const ResourceImage = styled.div<ImageContainerProps>`
  position: relative;
  width: ${boxWidth};
  height: ${boxWidth};
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.image});
  background-size: cover;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5), inset 0 0 4px rgba(0, 0, 0, 0.5), 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 1em;
  overflow: hidden;
`;

export const ResourceImageMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.6;
`;

export const ResourceTitle = styled.h2`
  color: ${(props) => props.theme.color.mainColor};
  hyphens: auto;

`;

export const ResourceDescription = styled.div`
  color: ${(props) => props.theme.color.text};
`;
