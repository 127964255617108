import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import LandingPage from "./views/LandingPage/Landing.page";
import VideoPage from "./views/Video/Video.page";
import FinalQuizPage from "./views/FinalQuiz/FinalQuiz.page";
import { observer, inject } from "mobx-react";
import Store from "./state/store";
import Actions from "./state/actions";
import { AppStyle, MainContainer } from "./App.style";
import SectionWrapper from "./components/SectionWrapper/SectionWrapper";
import { CircularProgress } from "@material-ui/core";
import { SectionType, DeprecatedSectionType } from "./types";
import PodcastPage from "./views/Podcast/Podcast.page";
import PdfPage from "./views/PdfPage/Pdf.page";
import { Debug } from "./components";
import IntroductionPage from "./views/IntroductionPage/IntroductionPage.view";
import RichTextPage from "./views/RichTextPage /RichText.page";
import ResourcePage from "./views/ResourcePage/Resource.page";
import ReactGA from "react-ga";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

interface Props {
  store?: Store;
  mutator?: Actions;
}

const SECTION_TYPE_COMPONENT_MAPPER: { [Property in SectionType | DeprecatedSectionType]: React.FC<any> } = {
  landingPage: LandingPage,
  videoPage: VideoPage,
  finalQuiz: FinalQuizPage,
  podcastPage: PodcastPage,
  pdfPage: PdfPage,
  introduction: IntroductionPage,
  richTextPage: RichTextPage,
  // TODO: Change the related components
  quiz: LandingPage,
  resourcePage: ResourcePage,
  resources: ResourcePage,
};

const App: React.FC<Props> = (props) => {
  const { content } = props.store!;
  const location = useLocation();
  const [isReady, setIsReady] = useState(false);
  const [cookieAcceptance, setCookieAcceptance] = useState(getCookieConsentValue());

  if (content.googleAnalyticsParams && cookieAcceptance) {
    ReactGA.initialize(content.googleAnalyticsParams.analyticsTrackingID, {
      debug: process.env.REACT_APP_DEBUG === "true" ? true : false,
    });
  }

  const instance = content["matomoTracking"]
    ? createInstance({
        urlBase: content["matomoTracking"]["urlBase"],
        siteId: content["matomoTracking"]["siteId"],
        configurations: {
          disableCookies: false,
          setSecureCookie: false,
        },
      })
    : undefined;

  const matomoTrackingWrapper = (children: JSX.Element): JSX.Element => {
    if (instance) return <MatomoProvider value={instance}>{children}</MatomoProvider>;
    return children;
  };

  // TODO: Is it stills necessary ? ABEDOS 01/06/2021
  const progress = () => {
    if (location.pathname === "/final-quiz") return content.sections.length + 1;
    for (let i = 0, n = content.sections.length; i < n; i++) {
      if (location.pathname.includes(content.sections[i].id)) return i + 1;
    }
    return 0;
  };

  const initializeApp = async () => {
    await props.mutator?.initSCORMState();

    if (content.shouldInitializeScore) {
      await props.mutator?.setScore(0);
    }

    setIsReady(true);
  };

  useEffect(() => {
    void initializeApp();

    return () => {
      props.mutator?.closeScorm();
    };
  }, []);

  return isReady ? (
    matomoTrackingWrapper(
      <AppStyle className="app-container">
        <Debug />
        {content.googleAnalyticsParams && (
          <CookieConsent
            buttonText={content.googleAnalyticsParams?.cookieAcceptButtonText}
            enableDeclineButton
            visible={cookieAcceptance === undefined ? "show" : "hidden"}
            declineButtonText={content.googleAnalyticsParams?.cookieDeclineButtonText}
            onAccept={() => setCookieAcceptance("true")}
            onDecline={() => setCookieAcceptance("false")}
            overlay
            style={{
              background: "black",
              color: "white",
            }}
            buttonStyle={{
              background: "green",
              color: "white",
              fontWeight: "bolder",
            }}
            declineButtonStyle={{ background: "red", color: "white" }}
          >
            <h2>{content.googleAnalyticsParams?.cookieComplianceTitle}</h2>
            <p>{content.googleAnalyticsParams?.cookieComplianceText}</p>
          </CookieConsent>
        )}

        <MainContainer className="main-container">
          <Switch location={location}>
            {content.sections.map((section) => (
              <Route
                key={section.id}
                path={`/section/${section.id}`}
                render={(props) => (
                  <SectionWrapper
                    idSection={section.id}
                    {...props}
                    Component={SECTION_TYPE_COMPONENT_MAPPER[section.type]}
                  />
                )}
              />
            ))}

            <Redirect from="/home" to={`/section/${content.sections[0].id}`} />
            <Redirect from="/" to={`/section/${content.sections[0].id}`} />
          </Switch>
        </MainContainer>
      </AppStyle>,
    )
  ) : (
    <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress size={50} />
    </div>
  );
};

export default inject("store", "mutator")(observer(App));
