import { StandardButton } from "../../components";
import { styled } from "../../theme";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 60vh;
  box-sizing: border-box;
  width: 100%;
`;

export const ResourceButton = styled.a`
  height: 1.5em;
  position: relative;
  width: 25%;
  border-radius: 36px;
  border: solid 0.1em #2A80B9;
  background-color: #2A80B9;
  color: #ffffff;
  padding: 0.15em;
  font-size: 1.5em;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.3s linear;
  margin: 0;
  &:hover {
    box-shadow: 0px 2px 5px 2px rgb(0 0 0 / 24%);
  }
  img {
    height: 100%;
    display: block;
    margin: auto;
  }
`;
