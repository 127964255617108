import React from "react";
import { observer, inject } from "mobx-react";
import Store from "../../state/store";
import { Footer, Header } from "../../components";
import { styled } from "../../theme";

interface Props {
  store?: Store;
  hideHeader?: boolean;
  hideFooter?: boolean;
}
export const MainChildContainer = styled.div`
  min-height: ${(props) => props.theme.contentHeight.desktop};
`;

const HeaderAndFooter: React.FC<Props> = (props) => {
  const { content } = props.store!;
  // TODO: Move these figures in consts or customizable content - ABEDOS 15/06/2021
  return (
    <React.Fragment>
      {" "}
      {!props.hideHeader && (
        <Header
          shouldDisplayBanner
          logo={content.branding.logo}
          decoration={content.branding.decoration}
          title={content.branding.title}
          subtitle={content.branding.subtitle || ""}
          color="#fff"
        />
      )}
      <MainChildContainer className={"content-container"}>{props.children}</MainChildContainer>
      {!props.hideFooter && <Footer itemList={content.footer} cookieCompliance={content.googleAnalyticsParams} />}
    </React.Fragment>
  );
};

export default inject("store")(observer(HeaderAndFooter));
