import { styled } from "../../theme";

export const SectionItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 1em;
  box-shadow: 0px 0px 1.5px 1px rgba(0, 0, 0, 0.1);
  min-width: 100%;
  height: 6em;
  /* border-radius: 1em; */
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  /* margin: 1em 1em 1em 1em; */
  /* &:hover {
    transform: scale(1.025);
  } */
`;

export const ImageContainer = styled.div`
  background: red;
  height: 100%;
  /* @media (min-width: 1475px) {
    width: 100%;
  }

  @media (max-width: 1475px) {
    width: 60%;
  } */
`;

interface ImageContainerProps {
  image: string;
}
export const ImageCard = styled.div<ImageContainerProps>`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
`;

export const SectionLeftContainer = styled.div`
  width: 20%;
  height: 100%;
`;

export const SectionIndex = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 10em;
  color: ${(props) => props.theme.color.lightText};
  margin: 0;
  font-weight: bolder;
  line-height: 1em;
  z-index: -1;
`;

export const SectionRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  padding: 1em;
`;

export const SectionButtonContainer = styled.div`
  position: absolute;
  bottom: -1em;
  align-self: center;
`;

export const SectionTitle = styled.div`
  color: ${(props) => props.theme.color.mainColor};
  /* hyphens: auto; */
`;

export const SectionDescription = styled.div`
  white-space: pre-wrap;
  color: ${(props) => props.theme.color.text};
`;

export const SectionDuration = styled.div`
  color: ${(props) => props.theme.color.mainColor};
  font-size: small;
  font-weight: bold;
`;
