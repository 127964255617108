import styled from "styled-components";

export const Picture = styled.div<{ bg: string }>`
  background-image: url(${({ bg }) => process.env.PUBLIC_URL + bg});
  width: 845px;
  height: 475px;
`;

export const IntroductionContent = styled.div`
  background-color: #fff;
  margin: 50px 0px 50px -100px;
  width: 450px;
  padding: 40px 55px 40px 54px;
`;

export const ButtonContainer = styled.div`
  margin: 1em 0;
`;
