import { Theme } from "./theme";
import { createGlobalStyle } from "styled-components";

const fontSuffix = "avenir";
const fontPath = "./fonts";

export const IndexStyle = createGlobalStyle<{ theme: Theme }>`

@font-face {
  font-family: "Avenir";
  font-style: normal;
  src: local("Avenir"), url("./fonts/avenir-light.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/avenir-light.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/avenir-light.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Hoffelized";
  font-weight: bold;
  src: local("Hoffelized"),
    /* Modern Browsers */ url("./fonts/Hoffelized.ttf") format("truetype"); /* Safari, Android, iOS */
}

* {
  margin: 0;
  padding: 0;
  line-height: 1.2em;
}

body {
  margin: 0;
  font-family: ${(props) =>
    props.theme.font
      .mainFont},  "Avenir,Work Sans", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

}
footer{
  border-top: 1px #e3e3e3 solid;
}
header{
  border-bottom: 1px #e3e3e3 solid;
}

.rounded {
  border-radius: 20px;
}
.MuiTypography-h5 {
  color: #2A80B9;
  text-align:center;
  font-weight:bold ;
  span {
    display:block;
    &:nth-of-type(2) {
      font-weight: normal ;
      font-size:0.6em ;
    }
  }
}
.MuiTypography-body2{
  color: ${(props) => props.theme.color.text};
}


.with-shadow {
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.07);
}

.fully-centered-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-expand {
  width: 100%;
  height: 100%;
}

.flex-col-margin {
  display: flex;
  flex-direction: column;
  margin: 0 10%;
  padding: 0 10%;

  @media (max-width: 768px) {
    margin: 0 0;
  }
}

.m-auto {
  margin: auto;
}

.with-shadow {
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.07);
}

.fully-centered-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-expand {
  width: 100%;
  height: 100%;
}

.flex-col-margin {
  display: flex;
  flex-direction: column;
  margin: 0 10%;
  padding: 0 10%;

  @media (max-width: 768px) {
    margin: 0 0;
  }
}

.standard-btn, .question-button-validate {
  border-radius: 2em;
  border: solid 0.1em ${(props) => props.theme.color.mainColor};
  background-color: ${(props) => props.theme.color.mainColor};
  color: #ffffff;
  padding: 0.5em 1.5em;
  height: inherit;
  font-size: 1.3em;

  cursor: pointer;
  font-family: ${(props) => props.theme.font.mainFont};
  font-weight: bolder;
  transition: all 0.3s linear;
  box-sizing: border-box;
  margin: 0;
  letter-spacing: unset;
  :hover {
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.24);
  }

  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    padding: 0.5em 24px;

  }
}

  .standard-text-size {
    font-size: 1.5em;

    @media (max-width: ${(props) => props.theme.responsive.mobile}) {
      font-size: 1.5em;
    }
  }

  .no-style-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
 
`;
