import { Content } from "./types";
import i18n from "i18next";

const computeUrl = (baseUrl: string) => {
  if (/^http(s)?:\/\//.test(baseUrl)) return baseUrl;
  if (/^\.\//.test(baseUrl)) return baseUrl;

  return process.env.PUBLIC_URL + baseUrl;
};

const homePageUrl = process.env.PUBLIC_URL;
const localUrl = process.env.LOCAL_URL;
const content: Content = {
  scormFilters: "STORYLINE_complete_incomplete_failed",
  idApplication: "7f47bb310de82dbadd8a89f6",
  matomoTracking: {
    siteId: 5,
    urlBase: "https://upandgo.matomo.cloud/",
  },
  /*  googleAnalyticsParams: {
    
    cookieComplianceText:
      "Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic, afin de mesurer et d’améliorer les performances de notre site Web. Ils nous aident également à identifier les pages les plus / moins visitées et d’évaluer comment les visiteurs naviguent sur le site Web. Toutes les informations collectées par ces cookies sont agrégées et donc anonymisées. Si vous n'acceptez pas ces cookies, nous ne serons pas informés de votre visite sur notre site.",
    cookieComplianceTitle: "Notre site utilise des cookies de performances",
    cookieAcceptButtonText: "J'accepte",
    cookieDeclineButtonText: "Je refuse",
  }, */
  shouldInitializeScore: true,
  footer: [
    {
      link: "http://www.upandgo.fr",
      type: "link",
      text: "© www.upandgo.fr",
    },
  ],
  branding: {
    logo: computeUrl(i18n.t("content:base.branding.logo")),
    decoration: computeUrl(i18n.t("content:base.branding.decoration")),
    title: i18n.t("content:base.branding.title"),
    subtitle: i18n.t("content:base.branding.subtitle"),
    mainIllustration: computeUrl(i18n.t("content:base.branding.mainIllustration")),
    mainIllustrationMobile: computeUrl(i18n.t("content:base.branding.mainIllustrationMobile")),
  },
  intro: i18n.t("content:base.intro"),
  totalTime: i18n.t("content:base.totalTime"),
  sections: [
    {
      id: "intro",
      index: 0,
      progressIncreaseOnComplete: 20,
      title: i18n.t("content:sections.intro.title"),
      description: i18n.t("content:sections.intro.description"),
      image: computeUrl(i18n.t("content:sections.intro.image")),
      type: "landingPage",
    },
    {
      id: "video",
      index: 2,
      title: i18n.t("content:sections.section01.title"),
      description: i18n.t("content:sections.section01.description"),
      image: computeUrl(i18n.t("content:sections.section01.image")),
      video: {
        url: computeUrl(i18n.t("content:sections.section01.video")),
        subtitle: computeUrl(i18n.t("content:sections.section01.subtitle")),
      },
      progressIncreaseOnComplete: 20,
      type: "videoPage",
      duration: "",
      progressOnPercentForCompletion: 80,
    },
    {
      id: "resources",
      index: 3,
      title: i18n.t("content:sections.section02.title"),
      description: i18n.t("content:sections.section01.description"),
      resources: [
        {
          id: "resource1",
          title: i18n.t("content:sections.section02.resources.0.title"),
          description: i18n.t("content:sections.section02.resources.0.description"),
          url: i18n.t("content:sections.section02.resources.0.url"),
          thumbnail: i18n.t("content:sections.section02.resources.0.thumbnail"),
        },
        {
          id: "resource2",
          title: i18n.t("content:sections.section02.resources.1.title"),
          description: i18n.t("content:sections.section02.resources.1.description"),
          url: i18n.t("content:sections.section02.resources.1.url"),
          thumbnail: i18n.t("content:sections.section02.resources.1.thumbnail"),
        },
      ],
      type: "resourcePage",
    },
  ],
  saveBookmark: false,
  canResetBookmark: false,
  credits: [""],
};

export default content;
