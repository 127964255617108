import styled from "styled-components";

export const HeaderTitleStyle = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 2em;
  font-family: "Title";
  color: white;
  text-align: center;
  padding: 0.1em;

  @media (min-width: 1475px) {
    font-size: 3em;
    width: 100%;
    white-space: wrap;
  }

  @media (max-width: 1475px) {
    font-size: 3em;
    white-space: wrap;
    max-height: 3em;
  }

  @media (max-width: 400px) {
    font-size: 1.8em;
    white-space: wrap;
    max-height: 3em;
    max-width: 70%;
  }
`;
