import React from "react";

interface Props {
  src: string;
  onClickRead: () => void;
}

const PdfReader: React.FC<Props> = (props) => {
  return (
    <div className="pdf-reader m-auto">
      <a href={props.src} target="__blank" onClick={props.onClickRead}>
        <img style={{ width: "100px" }} src="./img/pdf/play.svg" />
      </a>
    </div>
  );
};

export default PdfReader;
