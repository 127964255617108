import React, { useState, ChangeEvent, useRef } from "react";

interface Props {
  src: string;
  onProgressMeasureChange?: (progressInPercents: number) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onDurationChange?: (durationInSecs: number) => void;
  autoplay?: boolean;
}

const getAudioType = (url: string) => {
  const types = [
    {
      name: "mpeg",
      regex: "/*.mp3",
    },
    {
      name: "webm",
      regex: "/*.webm",
    },
    {
      name: "ogg",
      regex: "/*.ogg",
    },
    {
      name: "wav",
      regex: "/*.wav",
    },
    {
      name: "midi",
      regex: "/*.midi",
    },
  ];
  for (let i = 0, n = types.length; i < n; i++) {
    if (RegExp(types[i].regex).test(url)) return types[i].name;
  }

  return "";
};

const AudioPlayer: React.FC<Props> = (props) => {
  const [duration, setDuration] = useState(-1);
  const [isPlaying, setIsPlaying] = useState(props.autoplay || false);
  const ref = useRef<HTMLAudioElement>(null);

  const onDurationChange = (event: ChangeEvent<HTMLAudioElement>) => {
    setDuration(event.target.duration);
    props.onDurationChange && props.onDurationChange(event.target.duration);
  };

  const onTimeUpdate = (event: ChangeEvent<HTMLAudioElement>) => {
    const progressInPercent = Math.round((event.target.currentTime / duration) * 100);
    props.onProgressMeasureChange && props.onProgressMeasureChange(progressInPercent);
  };

  const onPlay = () => {
    setIsPlaying(true);
    props.onPlay && props.onPlay();
  };

  const onPause = (event: ChangeEvent<HTMLAudioElement>) => {
    if (event.target.seeking) return;

    setIsPlaying(false);
    props.onPause && props.onPause();
  };

  return (
    <div className="podcast-container m-auto">
      <audio
        className="podcast-audio-player"
        ref={ref}
        onPlay={onPlay}
        onPause={onPause}
        onDurationChange={onDurationChange}
        onTimeUpdate={onTimeUpdate}
        controls
        style={{ display: isPlaying ? "initial" : "none" }}
      >
        <source src={props.src} type={`audio/${getAudioType(props.src)}`} />
      </audio>
      {!isPlaying && (
        <button className="podcast-play-button no-style-btn" onClick={() => !isPlaying && ref?.current?.play()}>
          <img style={{ width: "100px" }} src="./img/podcast/play.svg" />
        </button>
      )}
    </div>
  );
};

export default AudioPlayer;
