import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "./i18n";
import App from "./App";
import content from "./Content";
import configureState from "./state/configureState";
import { Provider } from "mobx-react";
import { HashRouter as Router } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { IndexStyle } from "./index.style";

const { mutator, store } = configureState({
  content: cloneDeep(content),
  alreadySeenResourceIds: [],
  lockedSectionIds: [],
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <ThemeProvider theme={theme}>
      <Provider store={store} mutator={mutator}>
        <Router>
          <App />
          <IndexStyle />
        </Router>
      </Provider>
    </ThemeProvider>
  </I18nextProvider>,
  document.getElementById("root"),
);
