import React from "react";

const IconQuiz: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg data-name="Calque 1" viewBox="0 0 120 120" width="1em" height="1em" {...props}>
      <path
        d="M110,60A50,50,0,1,1,60,10,50,50,0,0,1,110,60ZM61.34,26.53a26.09,26.09,0,0,0-23.5,12.86,2.42,2.42,0,0,0,.55,3.27l7,5.31a2.43,2.43,0,0,0,3.36-.43c3.6-4.57,6.07-7.22,11.55-7.22,4.12,0,9.22,2.65,9.22,6.67,0,3-2.49,4.57-6.56,6.84-4.76,2.66-11,6-11,14.25v.81a2.42,2.42,0,0,0,2.42,2.41H65.65a2.41,2.41,0,0,0,2.42-2.41h0v-.27c0-5.74,16.77-6,16.77-21.51C84.84,35.41,72.71,26.53,61.34,26.53ZM60,76.53a9.28,9.28,0,1,0,9.28,9.27A9.28,9.28,0,0,0,60,76.53Z"
        fill="curentColor"
      />
    </svg>
  );
};

export default IconQuiz;
