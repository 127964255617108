import { styled } from "../../theme";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  border: dotted 1px blue;
`;

