import React from "react";
import { FailImageContainer, FailMainContainer, FailText, FailTitleContainer } from "./FailContainer.style";

interface Props {
  title: string;
  image: string;
  description: string;
}

const FailContainer: React.FC<Props> = (props) => {
  return (
    <FailMainContainer className="fail-main-container">
      <FailTitleContainer className="fail-title-container">
        <h2 className="fail-title">{props.title}</h2>
      </FailTitleContainer>

      <FailImageContainer className="fail-image container">
        <img className="fail-image" src={props.image} alt="visu" />
      </FailImageContainer>
      <FailText className="fail-text standard-text-size">{props.description}</FailText>
    </FailMainContainer>
  );
};

export default FailContainer;
