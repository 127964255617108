import { observable, computed } from "mobx";
import { Content, SectionQuiz, SectionFinalQuiz, CompletionStatus, SuccessStatus, SuspendData } from "../types";
import { findItemAndIndexById } from "../utils";

export interface AppState {
  content: Content;
  lockedSectionIds: string[];
  alreadySeenResourceIds: string[];
  score?: number;
  progressMeasure?: number;
}

export default class Store {
  constructor(initialState: AppState) {
    this.content = initialState.content;
    this.lockedSectionIds = initialState.lockedSectionIds;
    this.alreadySeenResourceIds = initialState.alreadySeenResourceIds;
    this.score = initialState.score || 0;
    this.progressMeasure = initialState.progressMeasure || 0;
  }
  @observable
  content: Content;

  @computed
  get completedSectionIds() {
    return Object.entries(this.suspendData.savedSections)
      .filter(([, savedSection]) => savedSection.done)
      .map(([id]) => id);
  }

  @observable
  lockedSectionIds: string[];

  @observable
  alreadySeenResourceIds: string[];

  @computed
  get savedSections() {
    return this.suspendData.savedSections;
  }

  @computed
  get savedFinalQuiz() {
    return this.suspendData.finalQuiz;
  }

  @observable
  suspendData: SuspendData = {
    savedSections: {},
    finalQuiz: {
      questionList: [],
    },
  };

  @observable
  score: number;

  @computed
  get finalQuiz() {
    return this.content.sections.find((sec) => sec.type === "finalQuiz") as SectionFinalQuiz;
  }

  @computed
  get finalQuizStartingIndex() {
    return Math.min(this.savedFinalQuiz.questionList.length, this.finalQuiz.quiz.questionList.length - 1);
  }

  @observable
  completionStatus: CompletionStatus = "not attempted";

  @observable
  successStatus: SuccessStatus = "unknown";

  @observable
  progressMeasure: number;

  @computed
  get quizSectionList() {
    return this.content.sections.filter<SectionQuiz>((section): section is SectionQuiz => section.type === "quiz");
  }

  getSection = (idSection: string) => {
    return findItemAndIndexById(idSection, this.content.sections);
  };
}
