import Store, { AppState } from "./store";
import Mutator from "./actions";
import { createWrapper } from "@upandgo/scorm-wrapper";

const configureState = (initialState: AppState) => {
  const store = new Store(initialState);
  const mutator = new Mutator({ store, createWrapper });
  return {
    store,
    mutator,
  };
};

export default configureState;
