import React from "react";

const IconInfo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 383.23 383.32">
      <g fill={props.fill} id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
          <path d="M180.37,0h23.21a20.8,20.8,0,0,0,2.88.61c28.79,2.17,55.84,10.21,80.64,25,51,30.31,82.21,74.65,93.19,133,1.3,7,2,14,2.94,21.05v23.2c-.22,1.2-.46,2.4-.64,3.61-1.56,10-2.43,20.08-4.76,29.86-27.33,114.6-151.42,177.6-259.95,132.13a191.45,191.45,0,0,1-115-209.7C16.32,81.14,76.39,19.25,153.41,3.9,162.3,2.12,171.38,1.28,180.37,0ZM71.83,191.62c0,65.87,53.6,119.69,119.29,119.77a120,120,0,0,0,120.24-120c0-65.73-53.77-119.54-119.5-119.54A119.82,119.82,0,0,0,71.83,191.62Z" />
          <path d="M191.79,87.77c57.85.56,104.29,47.53,103.65,104.82C294.79,249.73,247.9,295.93,191,295.48,133.57,295,87.19,247.94,87.75,190.67,88.31,133.79,135.38,87.24,191.79,87.77ZM165.64,219.46c.43,4.46.59,9,1.36,13.36,1.59,9.07,4.56,17.62,11.27,24.33,7.92,7.91,18.92,8.16,26.49-.06a45.26,45.26,0,0,0,8.49-13.83c5-12.61,5.11-25.77,2.52-39-1.71-8.69-4.89-16.75-11.54-23-7.59-7.1-17.76-7.26-25.14,0a42.25,42.25,0,0,0-7.69,10.87C167.1,200.73,165.89,210,165.64,219.46Zm25.75-47.88a25.72,25.72,0,1,0-25.49-25.8A25.57,25.57,0,0,0,191.39,171.58Z" />
          <path d="M201.92,220.86c-1,5.41-2,11.78-3.49,18a16.73,16.73,0,0,1-3.25,6.2C193,247.86,190,247.88,188,245a24.82,24.82,0,0,1-3.76-7.61,65.59,65.59,0,0,1,0-35.75,23.81,23.81,0,0,1,3.56-7.3c2.28-3.17,5.59-3.22,7.65.08a34,34,0,0,1,4.27,10.21C200.83,209.59,201.12,214.71,201.92,220.86Z" />
          <path d="M201.34,146.1a9.75,9.75,0,1,1-9.46-9.92A9.76,9.76,0,0,1,201.34,146.1Z" />
        </g>
      </g>
    </svg>
  );
};

export default IconInfo;
