import React from "react";

const IconPodcast: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 383.03 383.88">
      <g fill={props.fill} id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
          <path d="M180.4,0h23.2c1.21.23,2.42.48,3.63.67,10.32,1.68,20.83,2.57,30.92,5.15C332.47,30,392.75,117.22,381.74,214.19c-5.55,48.88-27.06,90.24-64.62,121.91C261.83,382.71,198.45,396,130,372.52,59.76,348.44,18,297.72,3,225c-1.44-7-2-14.26-3-21.4V179.65c1-7,1.74-14.06,2.93-21C16.24,81,77.08,18.67,154.19,3.78,162.84,2.1,171.66,1.24,180.4,0Zm75.46,159.69h-.52c0-9.1.26-18.21-.05-27.3-1.08-32.45-28.74-59.67-61.24-60.49C157.31,71,128.21,99,127.85,135.74c-.16,15.84-.13,31.68,0,47.51.25,34.21,24.7,60.79,58.75,64,32.37,3,63.35-20.72,67.78-52.85C255.94,183,255.42,171.27,255.86,159.69ZM183.58,292h0c0,3.62-.05,7.24,0,10.85.06,3.18,1.2,5.85,4.1,7.49,5.48,3.12,11.71-.69,11.91-7.51.2-7,.12-14,0-20.94,0-2.31.59-3.12,3.05-3.46q53.93-7.38,73.5-58.25a56.7,56.7,0,0,0,3-11.15c.87-5.43-2.85-9.44-8.05-9.27-4.11.14-6.48,2.48-7.77,7.69-14,56.34-79,71.45-118,40.29A71.69,71.69,0,0,1,119.81,207c-1.1-4.64-3.54-7-7.34-7.27-6.08-.43-9.65,4.45-8.15,11.12a87.67,87.67,0,0,0,76.26,67.55c2.52.25,3.14,1.17,3,3.46C183.47,285.23,183.58,288.6,183.58,292Z" />
          <path d="M238.89,159.67c0,10.47,1,21.05-.21,31.36-2.75,23.12-23.43,40.4-47,40.44s-43.89-17.26-47.31-40.24a55.34,55.34,0,0,1-.65-7.81q-.09-23.76,0-47.52c.1-24.12,18.27-45,41.28-47.59,25.65-2.86,47.68,12.39,53.31,37.05a52.21,52.21,0,0,1,1.16,10.37c.16,8,.05,16,.05,23.94Z" />
        </g>
      </g>
    </svg>
  );
};

export default IconPodcast;
