import React from "react";
import { DebugContainer } from "./Debug.style";
import { useWindowSize, useWindowPixelRatio } from "../../hooks";

const Debug: React.FC = () => {
  return (
    <DebugContainer debug={process.env.REACT_APP_DEBUG === "true" ? true : false}>
      {"window size:" + useWindowSize().width.toString() + "X" + useWindowSize().height.toString()}
      <br></br>
      {"pixelRatio :" + useWindowPixelRatio().pixelRatio.toString()}
    </DebugContainer>
  );
};
export default Debug;
