import React, { useEffect } from "react";
import { MainContainer } from "./RichText.style";
import Icon from "../../icons";
import { EnrichedProps } from "../../components/SectionWrapper/SectionWrapper";
import HeaderAndFooter from "../HeaderAndFooter/HeaderAndFooter.template";
import BaseContentSection from "../BaseContentSection/BaseContentSection";
import { SectionRichTextPage } from "../../types";

interface Props extends EnrichedProps {
  idSection: string;
}
const RichTextPage: React.FC<Props> = (props) => {
  
  useEffect(() => {
    void props.completeSection();
  }, []);
  const section = props.content as SectionRichTextPage;
  return (
    <HeaderAndFooter hideHeader={false}>
      <BaseContentSection {...props}>
        <MainContainer
          id={`section-${section.id}`}
          className="main-custom-container "
          dangerouslySetInnerHTML={{ __html: section.htmlContent || "" }}
        ></MainContainer>
      </BaseContentSection>
    </HeaderAndFooter>
  );
};

export default RichTextPage;
