import React from "react";

const IconCaseStudy: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 383.4 383.51">
      <g fill={props.fill} id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
          <path d="M203.73,383.51H179.78a14.88,14.88,0,0,0-2.47-.62,190.68,190.68,0,0,1-54.84-12.38c-89.32-34-140.32-132.17-116.72-224.8C37.38,21.57,176.72-38,287.46,26c51.35,29.67,82.21,74.72,93.05,133.19,1.27,6.83,1.94,13.79,2.89,20.69v23.21c-.22,1.2-.47,2.39-.64,3.6-1,7.52-1.7,15.12-3.14,22.56-14.92,77.18-77.35,138.14-154.87,151.34C217.78,381.75,210.74,382.54,203.73,383.51Zm52.63-217.43c.3-56.56-47.49-99.34-102.18-93.43A92,92,0,0,0,72.59,174.14C79.41,238,148,275.53,205.23,246.59c2.33-1.17,3.59-.94,5.39.89,12.53,12.7,25.18,25.27,37.8,37.88,6.35,6.35,12.57,12.85,19.11,19a25.84,25.84,0,0,0,36.42-.41c10.39-10.45,10.15-26.54-.76-37.53q-27.82-28-55.85-55.82c-1.66-1.64-2.09-2.86-.94-5.07A86.22,86.22,0,0,0,256.36,166.08Z" />
          <path d="M166.18,88c43.91,2,76.26,37.31,74.17,81.08-2,41.32-38.16,73.3-80.7,71.34-40.49-1.86-73.32-37.89-71.66-78.66A76.4,76.4,0,0,1,166.18,88Zm66.14,73.47c-2.44-28.28-16.15-48.66-42.3-60.23a63.12,63.12,0,0,0-25.11-5.12c-4.95,0-8.33,3.11-8.52,7.57-.2,4.61,2.72,7.79,7.7,8.33,1.36.15,2.74.2,4.11.29a51.66,51.66,0,0,1,45.93,37c1.38,4.86,1.71,10,2.3,15.09.52,4.51,3.74,7.86,8,7.87,4.4,0,7.76-3.46,7.92-8.2C232.35,163.22,232.32,162.34,232.32,161.47Z" />
          <path d="M222.64,236.82l14.67-14.52a38.93,38.93,0,0,0,3.1,3.88q24.95,25,50,50a32.55,32.55,0,0,1,3.05,3.28,10.09,10.09,0,0,1-.62,13,10.46,10.46,0,0,1-12.74,1.41,15.06,15.06,0,0,1-2.59-2.13q-27.12-27.09-54.2-54.21A5.57,5.57,0,0,1,222.64,236.82Z" />
        </g>
      </g>
    </svg>
  );
};

export default IconCaseStudy;
