import { styled } from "../../theme";
import { ButtonPill } from "../../components";
import { lighten } from "@material-ui/core";
export const FinalQuizContainer = styled.div`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    width: 100%;
  }

  .question-content {
    box-sizing: border-box;
    max-height: 85%;
    box-sizing: border-box;
    justify-content: space-between;
  }

  .question-column {
    box-sizing: border-box;
    max-height: 100%;
    width: 100%;

    &.context {
      flex-direction: column;
      align-items: flex-start;
      width: 48%;
      border-radius: 16px;
      overflow: hidden;

      @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
        flex-direction: column-reverse;
        margin: 1em 0;
        width: 100%;
      }
    }

    &.question {
    }

    &.no-context {
      width: 100%;

      .question-answers-container {
        justify-content: space-between;
        align-items: unset;
      }

      .answer-container {
        width: 48%;

        @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
          width: 100%;
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
      padding: 0;
      width: 100%;
    }
  }

  .question-context-description:empty {
    display: none;
  }

  .question-context-description {
    font-size: 1.25em;
    line-height: 1.2em;
    text-align: center;
    margin-top: 0em;
    margin-bottom: 0em;
    width: 100%;
    box-sizing: border-box;
    padding: 0.7em 1.1em 0.7em 1.1em;
    background-color: ${(props) => props.theme.color.mainColor};
    color: white;
    font-weight: bold;

    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
      font-size: 18px;
      z-index: 1;
      text-align: center;
    }
  }
  .question-context-title {
    display: none;
    font-size: 2.5em;
    margin-bottom: 0.2em;
    margin-top: 0.5em;
    justify-self: flex-start;
    font-family: ${(props) => props.theme.font.styledFont};
  }
  .answer-result-icon {
    right: -0.5em !important;
    top: -0.25em !important;
  }
  .question-title {
    font-family: ${(props) => props.theme.font.styledFont};
    font-size: 1.6em;
    font-weight: bolder;
    margin-bottom: 0;
    margin-top: 0 !important;
    box-sizing: border-box;
    margin: 16px 0 0 0;

    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
      max-width: 100%;
      font-size: 24px;
      margin-top: 1em !important;
    }
  }
  .question-instructions {
    margin-top: 0;
    margin: 1em 0 1.4em 0;
    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    }
  }
  .question-media-container {
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
    padding: 0;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
      max-width: 100%;
    }
  }
  .answer-container {
    font-size: 1.25em;
    line-height: 1.2em;
    margin: 0;
    width: 100%;
    margin-bottom: 1.3em;
    .answer-checkbox {
      background: #dadada5e;
      border-radius: 7px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    &.answer-container-wrong-answer {
      .answer-checkbox {
        background-color: ${(props) => lighten(props.theme.color.invalid, 0.9)}!important;
        color: ${(props) => props.theme.color.invalid}!important;
      }
    }
    &.answer-container-good-answer {
      .answer-checkbox {
        background-color: ${(props) => lighten(props.theme.color.valid, 0.9)}!important;
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
      text-align: center;
      font-size: 1.1em;
    }
  }
  .media-image {
    display: block;

    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
      border-radius: 0;
    }
  }
  .feedback-container {
    width: 100%;
    margin-top: 3em;
    box-shadow: 3px 1px 8px 3px rgb(0 0 0 / 25%);
    border-radius: 1em;
    .feedback-content {
      flex-direction: row-reverse;
      justify-content: space-around;
      margin-top: 0;
      @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
        max-width: 90%;
      }
    }
    .feedback-media-container {
      @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
        padding: 0;
      }
    }
    .media-image {
      border-radius: 16px;
    }
    .feedback-title:empty {
      display: none;
    }
    .feedback-title {
      font-family: ${(props) => props.theme.font.styledFont};
      color: ${(props) => props.theme.color.mainColor};
      font-size: 1.6em;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      font-weight: normal;
      text-align: center;

      @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
        font-size: 33px;
        max-width: 90%;
      }
    }
    .feedback-description-container {
      font-size: 1.25em;
      @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
        font-size: 18px;
        padding: 0;
        margin: 1em 0;
      }
    }
    .feedback-description {
      margin-top: 0;
      color: black;
      line-height: 1.4em;

      @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
        text-align: center;
      }
    }
  }

  .answer-checkbox {
    box-sizing: border-box;
    padding: 1em;
    font-weight: normal;
    text-align: center;
  }

  .validate-btn-container {
    margin: 1.5em 0;
  }
`;

export const QuizComponentContainer = styled.div``;

export const SectionDescription = styled.div`
  display: flex;
  justify-content: column;
  color: ${(props) => props.theme.color.text};
  align-items: center;
  margin: 1em;
`;

export const ResultTitle = styled.h2`
  color: ${(props) => props.theme.color.text};
  hyphens: auto;
`;

export const FinalQuizResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FinalQuizAnswersCountContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.2);
  padding: 1em;
  border-radius: 1em;
  background-color: #fff;
  color: ${(props) => props.theme.color.text};
  font-weight: bold;
  font-size: x-large;
  white-space: pre-line;
  margin-bottom: 2em;
`;

export const FinalQuizButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    width: 100%;
  }
  .congrats-back-to-home {
    margin-bottom: 1.5em;
  }
`;

interface ButtonFinalQuizResultsProps {
  secondary?: boolean;
}
export const ButtonFinalQuizResults = styled(ButtonPill)<ButtonFinalQuizResultsProps>`
  margin: 1em;

  ${(props) => (props.secondary ? `background-color: #FFFFFF;color: ${props.theme.color.text};` : "")}
`;

export const ResultCorrectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ResultCorrectionItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
