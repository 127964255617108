import React, { useState, useEffect } from "react";
import Store from "../../state/store";
import { inject, observer } from "mobx-react";
import {
  FinalQuizContainer,
  FinalQuizResultsContainer,
  ButtonFinalQuizResults,
  SectionDescription,
  QuizComponentContainer,
  FinalQuizButtonContainer,
} from "./FinalQuiz.style";
import { theme } from "../../theme";
import QuizComponent, { AnswerResult, isLastQuestion, isQuestionValid } from "@upandgo/react-quiz";
import Actions from "../../state/actions";
import { Certificate, StandardButton } from "../../components";
import HeaderAndFooter from "../HeaderAndFooter/HeaderAndFooter.template";
import { SectionFinalQuiz } from "../../types";
import { EnrichedProps } from "../../components/SectionWrapper/SectionWrapper";
import SuccessContainer from "./SuccessContainer";
import FailContainer from "./FailContainer";
import QuizBreadcrumb from "../../components/QuizBreadcrumb/QuizBreadcrumb";
import { useTranslation } from "react-i18next";
import BaseContentSection from "../BaseContentSection/BaseContentSection";

interface Props extends EnrichedProps {
  store?: Store;
  mutator?: Actions;
  idSection: string;
}

const CertificateButtonAndComponent = (props: { finalQuiz: SectionFinalQuiz; actions?: Actions; store: Store }) => {
  const [isDisplayingCertificate, setDisplayingCertificate] = useState(false);

  const onSubmitIdentity = async (identity: { firstName: string; lastName: string }) => {
    if (props.actions && props.finalQuiz.shouldSendDataTo) {
      try {
        await props.actions.sendSCORMStatsToServer({
          url: props.finalQuiz.shouldSendDataTo,
          identity: identity,
          scormState: {
            completionStatus: props.store.completionStatus,
          },
        });
      } catch (e) {
        alert(e);
      }
    }
  };

  const onClickEditCertificate = () => {
    setDisplayingCertificate(true);
  };

  if (isDisplayingCertificate)
    return (
      <div style={{ marginLeft: "1em", marginRight: "1em" }}>
        <Certificate
          {...props.finalQuiz.certificate!}
          password={props.finalQuiz.certificate?.password}
          onSubmitIdentity={onSubmitIdentity}
        />
      </div>
    );
  else
    return <ButtonFinalQuizResults onClick={onClickEditCertificate}>{"Éditer mon certificat"}</ButtonFinalQuizResults>;
};

const FinalQuiz: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { finalQuiz } = props.store!;
  const [step, setStep] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => window.scrollTo({ left: 0, top: 0 }), 10);
  }, [currentQuestionIndex]);

  const onAnswerQuestion = async ({ idQuestion, idAnswers }: AnswerResult) => {
    await props.mutator?.selectFinalQuizAnswer(idQuestion, idAnswers);
    if (isLastQuestion(finalQuiz.quiz, idQuestion)) {
      void props.completeSection();
      void props.mutator?.safelySetSuccessStatusTo(isSuccess ? "passed" : "failed");
    }
  };

  const onNextQuestion = (currentIndex: number) => {
    const nextIndex = currentQuestionIndex === finalQuiz.quiz.questionList.length - 1 ? 0 : currentQuestionIndex + 1;
    setCurrentQuestionIndex(nextIndex);
    if (currentIndex === finalQuiz.quiz.questionList.length - 1) setStep(step + 1);
  };

  const onClickRetry = async () => {
    await props.mutator?.resetFinalQuizAnswers();
    setStep(0);
    setCurrentQuestionIndex(0);
  };

  useEffect(() => {
    return () => {
      void onClickRetry();
    };
  }, []);

  const countValidAnswers = () => {
    return finalQuiz.quiz.questionList.reduce((acc, question) => acc + (isQuestionValid(question) ? 1 : 0), 0);
  };

  const isSuccess = countValidAnswers() >= finalQuiz.threshold;

  return (
    <React.Fragment>
      <HeaderAndFooter>
        <BaseContentSection {...props} hideBackground={true} hideNavigationButtons={true}>
          <FinalQuizContainer id={`section-${finalQuiz.id}`} className={"final-quiz-container"}>
            {finalQuiz.description && (
              <SectionDescription className={"final-quiz-description"}>{finalQuiz.description}</SectionDescription>
            )}
            {step === 0 && (
              <QuizComponentContainer className={`question_${currentQuestionIndex}`}>
                {/*   <QuizBreadcrumb
                  questionsLenght={finalQuiz.quiz.questionList.length}
                  currentQuestionIndex={currentQuestionIndex}
                /> */}
                <QuizComponent
                  theme={{
                    primaryColor: theme.color.mainColor,
                  }}
                  questionAggregate={finalQuiz.quiz}
                  onAnswerQuestion={onAnswerQuestion}
                  onNextQuestion={onNextQuestion}
                  text={{
                    next: t("common:next"),
                    validate: t("common:validate"),
                  }}
                />
              </QuizComponentContainer>
            )}
            {step === 1 && (
              <React.Fragment>
                <FinalQuizResultsContainer>
                  {finalQuiz.failContent != undefined && !isSuccess && (
                    <React.Fragment>
                      <FailContainer
                        title={finalQuiz.failContent.title}
                        description={finalQuiz.failContent.description}
                        image={finalQuiz.failContent.image}
                      ></FailContainer>
                    </React.Fragment>
                  )}
                  {(finalQuiz.failContent === undefined || isSuccess) && (
                    <React.Fragment>
                      <SuccessContainer
                        title={finalQuiz.successContent.title}
                        description={finalQuiz.successContent.description}
                        image={finalQuiz.successContent.image}
                      ></SuccessContainer>
                    </React.Fragment>
                  )}
                  <FinalQuizButtonContainer className={"final-quiz-button-container"}>
                     {/* <StandardButton
                    onClick={onClickRetry}
                    className="congrats-retry-button"
                    textButton="Revoir les situations"
                  /> */}
                    <StandardButton
                      onClick={props.onNext}
                      className={props.isLast ? "congrats-back-to-home" : "congrats-next-button"}
                      textButton={props.getNextButtonName()}
                    />
                  </FinalQuizButtonContainer>
                </FinalQuizResultsContainer>
              </React.Fragment>
            )}
          </FinalQuizContainer>
        </BaseContentSection>
      </HeaderAndFooter>
    </React.Fragment>
  );
};

export default inject("store", "mutator")(observer(FinalQuiz));
