import { CompletionStatus, SuccessStatus, Section } from "./types";

export const ORDERED_COMPLETION_STATUS: { [Property in CompletionStatus]: number } = {
  unknown: 0,
  "not attempted": 1,
  incomplete: 2,
  completed: 3,
};

export const ORDERED_SUCCESS_STATUS: { [Property in SuccessStatus]: number } = {
  unknown: 0,
  failed: 1,
  passed: 2,
};
