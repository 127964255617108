import { styled } from "../../theme";

export const IntroCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  margin-top: 1em;
  z-index: 1;
  background: #fff;
  padding: 0.5em 1em 0.5em 1em;
  text-align: center;
  color: ${props => props.theme.color.text};
`;

export const IntroButton = styled.div`
  font-weight: bold;
  cursor: pointer;
  color: ${props => props.theme.color.mainColor};
`;
