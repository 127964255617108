import React from "react";
import {
  HeaderContainer,
  Logo,
  HeaderLogoContainer,
  HeaderMainTitleStyle,
  HeaderBannerContainer,
  HeaderDecorationContainer,
  Decoration,
} from "./Header.style";
import { spanify } from "../../hooks";

interface Props {
  logo: string;
  decoration: string;
  color: string;
  title: string;
  subtitle: string;
  productLogo?: string;
  shouldDisplayBanner?: boolean;
}

const HeaderComponent: React.FC<Props> = (props) => {
  return (
    <HeaderContainer className="header-container">
      {props.shouldDisplayBanner && (
        <HeaderBannerContainer className="header-banner-container">
          <HeaderLogoContainer className="header-side-container">
            <Logo logo={props.logo} />
          </HeaderLogoContainer>
          <HeaderMainTitleStyle className="header-banner-title">{spanify(props.title)}</HeaderMainTitleStyle>
          <HeaderDecorationContainer className="header-side-container decoration">
            <Decoration decoration={props.decoration} />
          </HeaderDecorationContainer>
        </HeaderBannerContainer>
      )}
    </HeaderContainer>
  );
};

export default HeaderComponent;
