import baseStyled, { ThemedStyledInterface, keyframes } from "styled-components";

const HEADER_HEIGHT = 16;
const FOOTER_HEIGHT = 6;
const CONTENT_HEIGHT = 100 - (HEADER_HEIGHT + FOOTER_HEIGHT);

export const theme = {
  headerHeight: {
    desktop: `${HEADER_HEIGHT}vh`,
  },
  footerHeight: {
    desktop: `${FOOTER_HEIGHT}vh`,
  },
  contentHeight: { desktop: `${CONTENT_HEIGHT}vh` },
  //#081828
  //#2A80B9
  //#F39B1F
  color: {
    mainColor: "#F39B1F",
    mainTitleColor: "#2A80B9",
    sectionIconColor: "#F39B1F",
    landingPageBackgroundColor: "#FFF",
    headerBackgroundColor: "#FFF",
    footerBackgroundColor: "#FFF",
    text: "#707070",
    lightText: "#E3E3E3",
    valid: "#0D9353",
    invalid: "#DD3E3E",
    timePillColor: "#2A80B9",
  },
  responsive: {
    mobile: "768px",
    notebook: "1366px",
  },
  font: {
    styledFont: "Hoffelized",
    mainFont: "Avenir",
  },
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
export type styledAnimation = typeof keyframes;

export type StyleSheet = { [key: string]: React.CSSProperties };
