import Store from "./store";
import { action } from "mobx";
import {
  SectionQuiz,
  SavedSection,
  SAVED_SECTIONS_KEY,
  SectionFinalQuiz,
  CompletionStatus,
  SuccessStatus,
  SuspendData,
} from "../types";
import { findItemAndIndexById } from "../utils";
import { QuestionAggregate, getQuestion, isQuestionValid, isLastQuestion } from "@upandgo/react-quiz";
import { createWrapper } from "@upandgo/scorm-wrapper";
import content from "../Content";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import { ORDERED_COMPLETION_STATUS, ORDERED_SUCCESS_STATUS } from "../constants";
export default class Actions {
  private store: Store;
  private scormAPI: ReturnType<typeof createWrapper>;

  constructor(props: { store: Store; createWrapper: typeof createWrapper }) {
    this.store = props.store;
    this.scormAPI = props.createWrapper({
      moduleId: props.store.content.idApplication,
      baseStorageType:
        process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
          ? "local"
          : (process.env.REACT_APP_SCORM_STORAGE as "2004" | "local" | "1.2") || "2004",
      filteredScormValues: props.store.content.scormFilters,
    });
    void this.scormAPI.init({ startSession: true, isDebug: process.env.REACT_APP_DEBUG === "true" });
  }

  @action
  initSCORMState = async () => {
    await Promise.all([
      this.getScore(),
      this.getSuspendData(),
      this.getProgressMeasure(),
      this.fetchCompletionStatus(),
    ]);
  };

  setCurrentScormState = () =>
    Promise.all([
      this.setSuspendData(this.store.suspendData),
      this.setScore(this.store.score),
      this.setProgressMeasure(this.store.progressMeasure),
      this.safelySetCompletionStatusTo(this.store.completionStatus),
    ]);

  sendSCORMStatsToServer = async (props: { url: string; identity: any; scormState: any }) => {
    await axios.post(props.url, {
      applicationId: this.store.content.idApplication,
      user: props.identity,
      scormState: props.scormState,
    });
  };

  closeScorm = () => {
    this.scormAPI.close();
  };

  @action
  getSuspendData = async () => {
    const rawSuspendData = await this.scormAPI.getBaseData("suspendData");

    if (!rawSuspendData || rawSuspendData === "undefined" || rawSuspendData === "null") return;

    this.store.suspendData = { ...this.store.suspendData, ...(JSON.parse(rawSuspendData) || {}) };
  };

  @action
  setSuspendData = async (suspendData: SuspendData) => {
    await this.scormAPI.setBaseData("suspendData", JSON.stringify(suspendData));
    this.store.suspendData = suspendData;
  };

  @action
  seeResource = (resourceId: string) => {
    if (!this.store.alreadySeenResourceIds.includes(resourceId)) this.store.alreadySeenResourceIds.push(resourceId);
  };

  //TODO: Is this necessary here ?? ABEDOS 31/05/2021
  getQuizWithSelectedAnswers = (quiz: QuestionAggregate, idQuestion: string, idAnswers: string[]) => {
    const newQuiz = { ...quiz };
    const questionSearch = findItemAndIndexById(idQuestion, quiz.questionList);
    const question = questionSearch.item;
    for (let i = 0, n = question.answerList.length; i < n; i++) {
      question.answerList[i].selected = idAnswers.includes(question.answerList[i].id);
    }
    newQuiz.questionList[questionSearch.index] = question;
    return newQuiz;
  };

  @action
  selectFinalQuizAnswer = async (idQuestion: string, idAnswers: string[]) => {
    const finalQuizIndex = this.store.content.sections.findIndex((sec) => sec.type === "finalQuiz");
    (this.store.content.sections[finalQuizIndex] as SectionFinalQuiz).quiz = {
      ...this.getQuizWithSelectedAnswers(this.store.finalQuiz.quiz, idQuestion, idAnswers),
    };

    await this.setSuspendData({
      ...this.store.suspendData,
      finalQuiz: {
        questionList: this.store.finalQuiz.quiz.questionList
          .filter((question) => question.answerList.some((answer) => answer.selected))
          .map((question) => ({
            id: question.id,
            answerList: question.answerList.filter((answer) => answer.selected).map((answer) => answer.id),
          })),
      },
    });

    if (isLastQuestion(this.store.finalQuiz.quiz, idQuestion)) {
      const numberOfQuestions = this.store.finalQuiz.quiz.questionList.length || 1;
      const validAnswers = this.store.finalQuiz.quiz.questionList.reduce(
        (acc, question) => acc + (isQuestionValid(question) ? 1 : 0),
        0,
      );
      await this.setScore((validAnswers / numberOfQuestions) * 100);

      if (validAnswers >= this.store.finalQuiz.threshold) {
        await this.markAsPassed();
      }
    }
  };

  @action
  resetFinalQuizAnswers = async () => {
    await this.setSuspendData({ ...this.store.suspendData, finalQuiz: { questionList: [] } });

    const finalQuizIndex = content.sections.findIndex((sec) => sec.type === "finalQuiz");
    this.store.content.sections[finalQuizIndex] = cloneDeep(content.sections[finalQuizIndex]);
  };

  // Score tracking
  @action
  getScore = async () => {
    const score = await this.scormAPI.getBaseData("scoreRaw");

    if (!score) {
      this.store.score = 0;

      return;
    }

    this.store.score = score;
  };

  @action
  setScore = async (newValue: number) => {
    if (newValue >= this.store.score) {
      await this.scormAPI.setBaseData("scoreRaw", Math.round(newValue));
      await this.scormAPI.setBaseData("scoreScaled", Math.round(newValue) / 100);

      this.store.score = Math.round(newValue);
    }
  };

  // Progress measure tracking
  @action
  getProgressMeasure = async () => {
    const progressMeasure = await this.scormAPI.getBaseData("progressMeasure");

    // TODO: why undefined is returned as string ???
    // Check SCORM container maybe auto casting ABEDOS - 02/07/2021
    if (!progressMeasure) {
      this.store.progressMeasure = 0;

      return;
    }

    this.store.progressMeasure = Math.round((progressMeasure) * 100) / 100;
  };

  @action
  setProgressMeasure = async (measureInPercents: number) => {
    const newProgressMeasure = Math.round(measureInPercents) / 100;

    if (newProgressMeasure < this.store.progressMeasure) {
      return;
    }

    let checkedProgressMeasure = newProgressMeasure;

    if (checkedProgressMeasure < 0) {
      checkedProgressMeasure = 0;
    } else if (checkedProgressMeasure > 1) {
      checkedProgressMeasure = 1;
    }

    this.store.progressMeasure = checkedProgressMeasure;

    return this.scormAPI.setBaseData("progressMeasure", checkedProgressMeasure);
  };

  @action
  incrementProgressMeasureBy = async (incrementInPercents: number) => {
    if (incrementInPercents < 0) {
      return;
    }

    const newProgressMeasure = this.store.progressMeasure * 100 + Math.round(incrementInPercents);

    return this.setProgressMeasure(newProgressMeasure);
  };

  // Complete status tracking
  @action
  _setCompletionStatusTo = async (newStatus: CompletionStatus) => {
    await this.scormAPI.setBaseData("completeStatus", newStatus);
    this.store.completionStatus = newStatus;
  };

  @action
  safelySetCompletionStatusTo = async (targetedStatus: CompletionStatus) => {
    const currentStatus = this.store.completionStatus;

    if (ORDERED_COMPLETION_STATUS[targetedStatus] < ORDERED_COMPLETION_STATUS[currentStatus]) {
      return;
    }

    return this._setCompletionStatusTo(targetedStatus);
  };

  @action
  computeGlobalCompleteStatus = async () => {
    let completeStatus: CompletionStatus = "incomplete";
    const completedSections = this.store.completedSectionIds || [];
    if (completedSections.length === this.store.content.sections.length) {
      completeStatus = "completed";
    }
    return this.safelySetCompletionStatusTo(completeStatus);
  };

  // Success status tracking
  @action
  _setSuccessStatusTo = async (newStatus: SuccessStatus) => {
    await this.scormAPI.setBaseData("successStatus", newStatus);
    this.store.successStatus = newStatus;
  };

  @action
  safelySetSuccessStatusTo = async (targetedStatus: SuccessStatus) => {
    const currentStatus = this.store.successStatus;

    if (ORDERED_SUCCESS_STATUS[targetedStatus] < ORDERED_SUCCESS_STATUS[currentStatus]) {
      return;
    }

    return this._setSuccessStatusTo(targetedStatus);
  };

  @action
  markAsPassed = async () => Promise.all([this._setSuccessStatusTo("passed"), this.setProgressMeasure(100)]);

  @action
  fetchCompletionStatus = async () => {
    const status = await this.scormAPI.getBaseData("completeStatus");

    if (typeof status === "string") {
      this.store.completionStatus = status as CompletionStatus;
    }
  };

  @action
  updateSavedSection = async (idSection: string, save: Partial<Omit<SavedSection, "id">>) => {
    const sectionSearch = findItemAndIndexById(idSection, this.store.content.sections);
    if (sectionSearch.item && sectionSearch.item.ignoreTracking) return;

    const savedSections = this.store.suspendData[SAVED_SECTIONS_KEY];

    const savedSection: SavedSection = {
      ...{
        done: false,
        validQuestionList: [],
        primaryResourceSeen: [],
        secondaryResourceSeen: [],
      },
      ...savedSections[idSection],
    };

    const newSavedSections = {
      ...this.store.suspendData[SAVED_SECTIONS_KEY],
      [idSection]: { ...savedSection, ...save },
    };

    await this.setSuspendData({ ...this.store.suspendData, [SAVED_SECTIONS_KEY]: newSavedSections });
  };

  @action
  selectAnswers = async (idSection: string, idQuestion: string, idAnswers: string[]) => {
    const sectionSearch = findItemAndIndexById(idSection, this.store.content.sections);

    if (sectionSearch.item.type === "quiz") {
      (this.store.content.sections[sectionSearch.index] as SectionQuiz).quiz = this.getQuizWithSelectedAnswers(
        (this.store.content.sections[sectionSearch.index] as SectionQuiz).quiz,
        idQuestion,
        idAnswers,
      );
      const question = getQuestion((this.store.content.sections[sectionSearch.index] as SectionQuiz).quiz, idQuestion);
      if (isQuestionValid(question.item)) {
        const savedSection: SavedSection = {
          ...{
            done: false,
            validQuestionList: [],
            primaryResourceSeen: [],
            secondaryResourceSeen: [],
          },
          ...this.store.savedSections[idSection],
        };
        await this.updateSavedSection(idSection, {
          validQuestionList: [...savedSection.validQuestionList, idQuestion],
        });
      }
    }
  };
}
