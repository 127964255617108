import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resources from "./locales";

export const SUPPORTED_LANGUAGE = ["fr","en", "de"];
export const DEFAULT_LANGUAGE = "fr";

export const getCurrentLang = () => {
  return i18n.language;
};

export const changeLang = (lang: string) => {
  document.documentElement.setAttribute("lang", lang);
  void i18n.changeLanguage(lang);
};

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    detection: {
      order: ["querystring"],
      lookupQuerystring: "lng",
    },
    resources: resources,
    fallbackLng: process.env.REACT_APP_DEFAULT_LNG || DEFAULT_LANGUAGE,
    supportedLngs: SUPPORTED_LANGUAGE,
    load: "languageOnly",
    cleanCode: true,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
