import { styled } from "../../theme";

export const Video = styled.video`
  border-radius: 20px;
  width: 100%;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    width: 100%;
    max-width: 100%;
  }
`;
