import React from "react";
import { theme } from "../theme";

const IconPdf: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <path
                    fill="#EBF0F3"
                    d="M35.678 0H1.59C.754 0 0 .67 0 1.591V65.41C0 66.246.67 67 1.591 67h47.57c.838 0 1.592-.67 1.592-1.591V15.075c0-.419-.168-.838-.42-1.089L36.767.42C36.431.168 36.096 0 35.678 0z"
                    transform="translate(-302.000000, -188.000000) translate(148.000000, 149.000000) translate(154.000000, 39.000000) translate(0.000000, 0.000000) translate(8.204082, 0.000000)"
                  />
                  <path
                    fill="#D5D6DB"
                    d="M37.855 14.405h12.73c-.084-.168-.167-.335-.335-.419l-6.784-6.784-6.7-6.783C36.6.25 36.515.168 36.347.084v12.73c-.083.921.67 1.591 1.508 1.591z"
                    transform="translate(-302.000000, -188.000000) translate(148.000000, 149.000000) translate(154.000000, 39.000000) translate(0.000000, 0.000000) translate(8.204082, 0.000000)"
                  />
                  <path
                    fill="#E56353"
                    d="M11.055 59.211c-1.172 0-2.094-.67-2.513-1.089-1.423-1.675-1.423-3.098-1.172-4.02.168-.837.67-1.59 1.424-2.093 1.089-.838 4.103-2.932 8.542-4.774 2.345-4.271 3.937-7.705 5.025-10.469-1.842-3.517-2.512-6.365-2.68-7.789-.167-1.172-.083-2.345.251-3.35.838-2.68 2.68-2.763 3.434-2.68.168 0 3.099.42 3.601 4.607 0 .167.335 2.847-1.926 9.128 1.424 2.43 3.434 5.11 6.533 7.705 2.93 0 6.113.252 9.463 1.09.754.167 1.424.585 1.927 1.172.837.921 1.005 2.094.586 3.266 0 0-.168.586-.67 1.173-.921 1.256-2.68 1.675-4.104.92-3.182-1.674-5.862-3.433-8.04-5.275-4.439.167-8.375 1.172-11.557 2.428-1.424 2.597-3.183 5.444-5.193 8.626-.419.587-.921 1.09-1.591 1.34-.586 0-1.005.084-1.34.084zm4.104-8.459c-2.513 1.34-4.271 2.513-4.942 3.1-.25.25-.502.502-.502.753-.168.586.084 1.172.67 1.842 0 0 .503.42 1.005.252.168-.084.335-.168.419-.42 1.172-1.925 2.345-3.768 3.35-5.527zm19.513-4.02c1.508 1.005 3.183 2.094 5.025 3.015.335.168.754.084 1.005-.25.252-.336.335-.587.335-.587.168-.419.168-.586-.083-.838-.168-.167-.42-.334-.754-.418-1.843-.503-3.685-.754-5.528-.922zM23.785 39.28c-.838 1.926-1.843 4.103-3.183 6.616 2.262-.67 4.69-1.256 7.37-1.507-1.675-1.76-3.098-3.518-4.187-5.11zm-.837-14.07c-.335 0-.587.335-.838 1.088-.251.67-.251 1.424-.168 2.262.168 1.005.503 2.68 1.424 4.857 1.256-4.02 1.089-5.695 1.089-5.695-.251-2.261-1.424-2.512-1.507-2.512z"
                    transform="translate(-302.000000, -188.000000) translate(148.000000, 149.000000) translate(154.000000, 39.000000) translate(0.000000, 0.000000) translate(8.204082, 0.000000)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconPdf;
