import React, { useEffect, useRef, useState } from "react";
import { MainContainer, ResourceButton } from "./Resource.style";
import { EnrichedProps } from "../../components/SectionWrapper/SectionWrapper";
import HeaderAndFooter from "../HeaderAndFooter/HeaderAndFooter.template";
import BaseContentSection from "../BaseContentSection/BaseContentSection";
import { SectionResources } from "../../types";
import { Card, CardActions, CardContent, CardMedia, Typography, Grid, Box } from "@material-ui/core";
import { StandardButton } from "../../components";
import { spanify } from "../../hooks";
import { findItemAndIndexById } from "../../utils";

interface Props extends EnrichedProps {
  idSection: string;
}
const ResourcePage: React.FC<Props> = (props) => {
  useEffect(() => {
    void props.completeSection();
  }, []);
  const ref = useRef<HTMLUListElement>(null);
  const section = props.content as SectionResources;

  return (
    <HeaderAndFooter hideHeader={false}>
      <BaseContentSection {...props}>
        <MainContainer id={`section-${section.id}`} className="main-resources-container">
          <Box alignContent={section.resources.length < 3 ? "center" : "flex-start"}>
            <Grid
              container
              spacing={4}
              justifyContent={section.resources.length < 3 ? "center" : "flex-start"}
              alignContent={section.resources.length < 3 ? "center" : "flex-start"}
            >
              {section.resources.map((res, i) => (
                <Grid key={i} item xs={12} md={5}>
                  <Card style={{ width: "100%", borderRadius: "16px" }} component={"div"}>
                    <CardMedia
                      component="img"
                      alt=""
                      style={{ height: "12em" }}
                      width={"unherit"}
                      image={res.thumbnail}
                    />
                    <CardContent style={{ height: "10em" }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {spanify(res.title)}
                      </Typography>
                      <Typography variant="body2">{res.description}</Typography>
                    </CardContent>
                    <CardActions style={{ display: "flex", justifyContent: "center" }}>
                      <ResourceButton href={res.url} target="_blank" rel="noreferrer">
                        <img src="./img/icon-magnifier.svg" />
                      </ResourceButton>
                    </CardActions>
                  </Card>
                </Grid>
                /*   */
              ))}
            </Grid>
          </Box>
        </MainContainer>
      </BaseContentSection>
    </HeaderAndFooter>
  );
};

export default ResourcePage;
