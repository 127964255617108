import { QuestionAggregate } from "@upandgo/react-quiz";
import { createWrapper } from "@upandgo/scorm-wrapper";

export type CompletionStatus = "completed" | "incomplete" | "not attempted" | "unknown";
export type SuccessStatus = "passed" | "failed" | "unknown";

export interface LandingCards {
  imageCard: string;
  id: string;
  index: number;
  mainTitle: string;
  description: string;
}

export interface HomeButtons {
  id: string;
  index: number;
  mainTitle: string;
  description: string;
}
export interface GoogleAnalyticsParams {
  analyticsTrackingID: string;
  cookieComplianceTitle: string;
  cookieComplianceText: string;
  cookieAcceptButtonText: string;
  cookieDeclineButtonText: string;
}

export interface Content {
  idApplication: string;
  branding: Branding;
  sections: Section[];
  googleAnalyticsParams?: GoogleAnalyticsParams;
  matomoTracking?: {
    urlBase: string;
    siteId: number;
  };
  saveBookmark?: boolean;
  canResetBookmark?: boolean;
  shouldInitializeScore?: boolean;
  intro: string;
  credits: string[];
  totalTime?: string;
  footer: FooterItem[];
  scormFilters?: Parameters<typeof createWrapper>[0]["filteredScormValues"];
}

export type FooterLink = {
  type: "link";
  link: string;
  text: string;
};

export type FooterContent = {
  type: "content";
  content: string;
  text: string;
};

export type FooterItem = FooterLink | FooterContent;

export interface Branding {
  logo: string;
  decoration: string;
  title: string;
  subtitle?: string;
  productLogo?: string;
  mainIllustration: string;
  mainIllustrationMobile?: string;
}

export interface Intro {
  pages: IntroPage[];
}

export interface IntroPage {
  content?: string;
  video?: string;
}

export type MediaSection = "videoPage" | "podcastPage" | "pdfPage" | "resourcePage" | "richTextPage" | "finalQuiz";
export type SectionType = MediaSection | "landingPage" | "introduction";
export type DeprecatedSectionType = "quiz" | "resources";

export interface BaseSection {
  id: string;
  index: number;
  title: string;
  description: string;
  duration?: string;
  image?: string;
  lockedBy?: string;
  ignoreTracking?: boolean;
  progressIncreaseOnComplete?: number;
  type: SectionType | DeprecatedSectionType;
}

export interface SectionLandingPage extends BaseSection {
  type: "landingPage";
  content?: string;
  video?: string;
}

export interface SectionVideo extends BaseSection {
  type: "videoPage";
  video: Video;
  progressOnPercentForCompletion?: number;
}

export interface SectionPodcast extends BaseSection {
  type: "podcastPage";
  audio: string;
  progressOnPercentForCompletion?: number;
}

export interface SectionPdf extends BaseSection {
  type: "pdfPage";
  pdf: string;
}
export interface SectionRichTextPage extends BaseSection {
  type: "richTextPage";
  htmlContent: string;
}
export interface SectionResources extends BaseSection {
  type: "resourcePage";
  resources: Resource[];
}

export interface SectionIntroduction extends BaseSection {
  type: "introduction";
  buttonText: string;
}

export interface SectionQuiz extends BaseSection {
  type: "quiz";
  outro:
    | {
        type: "resources";
        mainResourceList: Resource[];
        secondaryResourceList: Resource[];
      }
    | {
        type: "congrats";
        title: string;
        description: string;
        image: string;
      };
  quiz: QuestionAggregate;
  allowUnlockAllResources?: boolean;
}

export interface SectionFinalQuiz extends BaseSection {
  type: "finalQuiz";
  threshold: number;
  duration: string;
  certificate?: {
    password?: string;
    backgroundImage: string;
    formationName: string;
    companyLogo: string;
    formationLogo: string;
    primaryColor: string;
    secondaryColor: string;
  };
  shouldSendDataTo?: string;
  quiz: QuestionAggregate;
  successContent: {
    title: string;
    description: string;
    image: string;
  };
  failContent?: {
    title: string;
    description: string;
    image: string;
  };
}

export interface SectionResources extends BaseSection {
  type: "resourcePage";
  resources: Resource[];
}

// TODO: Sections to be removed ? Are not implemented so far. ABEDOS - 14/06/2021
export type Section =
  | SectionQuiz
  | SectionVideo
  | SectionPodcast
  | SectionPdf
  | SectionFinalQuiz
  | SectionResources
  | SectionIntroduction
  | SectionLandingPage
  | SectionRichTextPage;
// export type Section = SectionVideo | SectionFinalQuiz | SectionLandingPage;

export interface Resource {
  id: string;
  title: string;
  thumbnail: string;
  url: string;
  description?: string;
  hideByQuestionValidation?: string[];
  alreadySeen?: boolean;
}

export interface Video {
  url: string;
  subtitle?: string;
}

export interface SavedSection {
  done: boolean;
  validQuestionList: string[];
  primaryResourceSeen: string[];
  secondaryResourceSeen: string[];
}
export const SAVED_SECTIONS_KEY = "savedSections";

export interface SavedFinalQuiz {
  questionList: {
    id: string;
    answerList: string[];
  }[];
}

export interface SuspendData {
  savedSections: { [key: string]: SavedSection };
  finalQuiz: SavedFinalQuiz;
}
