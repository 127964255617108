import { styled } from "../../theme";
import { TransitionStatus } from "react-transition-group/Transition";

interface TransitionProps {
  transition: TransitionStatus;
}
export const SidebarContainer = styled.div<TransitionProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  border-right: solid 1px ${props => props.theme.color.lightText};
  transition: 0.5s;
  overflow: hidden;
  width: ${({ transition }) =>
    transition === "entering" || transition === "entered" ? "18em" : "4em"};
  min-width: ${({ transition }) =>
    transition === "entering" || transition === "entered" ? "18em" : "4em"};
`;

export const SidebarHeaderContainer = styled.div<TransitionProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: solid 1px ${props => props.theme.color.lightText};
  padding: ${({ transition }) =>
    transition === "entering" || transition === "entered"
      ? "0em 1em 0em 1em"
      : "0"};
  max-height: 18em;
  max-width: 100%;
`;
export const Logo = styled.img`
  width: 100%;
  height: auto !important;
  border-radius: 0em 0em 1em 1em;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5),
    inset 0 0 4px rgba(0, 0, 0, 0.5), 0 0 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  min-height: 1px;
`;

export const Title = styled.div<TransitionProps>`
  display: flex;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
  font-weight: bold;
  font-size: x-large;
  color: ${props => props.theme.color.text};
  white-space: pre-line;
  overflow: hidden;
  height: ${({ transition }) =>
    transition === "entering" || transition === "entered"
      ? "auto !important"
      : "0"};
  width: 100%;
`;

export const SidebarContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.4em;
  width: 100%;
`;

interface ItemProps {
  activated: boolean;
}

export const SidebarFooterContainer = styled.div<TransitionProps>`
  overflow: hidden;
  border-top: solid 1px ${props => props.theme.color.lightText};
  max-height: ${({ transition }) =>
    transition === "entering" || transition === "entered" ? "auto" : "0"};
`;

export const ItemHomeTitle = styled.div`
  color: ${props => props.theme.color.mainColor};
  margin-left: 4px;
`;

export const ContentItemIndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentItemIndexDivider = styled.div<ItemProps>`
  width: 2px;
  height: 2em;
  background-color: ${props =>
    props.activated ? props.theme.color.mainColor : props.theme.color.text};
`;

export const ContentItemLeftcontainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 3em;
  justify-content: center;
  margin-left: 4px;
  width: 100%;
`;

export const ContentItemTitle = styled.div<ItemProps>`
  color: ${props =>
    props.activated ? props.theme.color.mainColor : props.theme.color.text};
`;

export const ContentItemDescription = styled.div`
  display: flex;
  color: ${props => props.theme.color.text};
  font-size: small;
  text-overflow: ellipsis;
  max-height: 2em;
  overflow: hidden;
`;

export const ContentItemIndex = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  min-width: 3em;
  height: 3em;
  border-radius: 1.5em;
  border-style: solid;
  box-sizing: border-box;
  border-color: ${props =>
    props.activated ? props.theme.color.mainColor : props.theme.color.text};
  border-width: ${props => (props.activated ? "5px" : "1px")};
  font-size: large;
  font-weight: ${props => (props.activated ? "bold" : "normal")};
  color: ${props =>
    props.activated ? props.theme.color.mainColor : props.theme.color.text};
`;

export const ContentItemContainer = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  &:hover ${ContentItemIndex} {
    background-color: ${props => props.theme.color.headerBackgroundColor};
  }
  width: 100%;
`;

export const ItemHomeContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover ${ContentItemIndex} {
    background-color: ${props => props.theme.color.headerBackgroundColor};
  }
`;

export const ChevronContainer = styled.div`
  position: absolute;
  background-color: #fff;
  right: -2em;
  top: 5px;
  width: 2em;
  height: 2em;
  border-radius: 0 1em 1em 0;
  box-sizing: border-box;
  border: solid 1px ${props => props.theme.color.lightText};
  border-left-width: 0;
  cursor: pointer;
`;

export const ChevronIconContainer = styled.div<TransitionProps>`
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  transform: rotate(
    ${({ transition }) =>
      transition === "entering" || transition === "entered" ? "180deg" : "0"}
  );
`;
