import React from "react";
import { Introduction } from "../../components";
import { EnrichedProps } from "../../components/SectionWrapper/SectionWrapper";
import { SectionIntroduction } from "../../types";
import HeaderAndFooter from "../HeaderAndFooter/HeaderAndFooter.template";
import { CardContainer } from "./IntroductionPage.style";

const IntroductionPage: React.FC<EnrichedProps> = (props) => {
  const content = props.content as SectionIntroduction;

  return (
    <HeaderAndFooter>
      <CardContainer>
        <Introduction
          duration={content.duration}
          title={content.title}
          description={content.description}
          buttonText={content.buttonText}
          bgPicture={content.image}
          onClickStart={props.onNext}
        />
      </CardContainer>
    </HeaderAndFooter>
  );
};

export default IntroductionPage;
