import React from "react";
import { Content, Picture, Container, IllustrationContainer } from "./TransitionCard.style";

interface Props {
  bgPicture: string;
}

const TransitionCard: React.FC<Props> = (props) => {
  return (
    <Container className="transition-container">
      <IllustrationContainer className="fully-centered-flex">
        <Picture className="rounded transition-picture" src={props.bgPicture} />
      </IllustrationContainer>
      <Content className="rounded with-shadow transition-content">{props.children}</Content>
    </Container>
  );
};

export default TransitionCard;
