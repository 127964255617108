import React from "react";
import { PdfReader } from "../../components";
import { SectionPdf } from "../../types";
import { EnrichedProps } from "../../components/SectionWrapper/SectionWrapper";
import HeaderAndFooter from "../HeaderAndFooter/HeaderAndFooter.template";
import BaseContentSection from "../BaseContentSection/BaseContentSection";

const PdfPage: React.FC<EnrichedProps> = (props) => {
  const section = props.content as SectionPdf;

  return (
    <HeaderAndFooter>
      <BaseContentSection {...props}>
        <PdfReader src={section.pdf} onClickRead={() => props.completeSection()} />
      </BaseContentSection>
    </HeaderAndFooter>
  );
};

export default PdfPage;
