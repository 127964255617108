import styled from "styled-components";
import { theme } from "../../theme";

export const FlexColWithMargin = styled.div`
  display: flex;
  flex-direction: column;
  width: min(1200px, 80%);
  margin: auto;
  //margin: 0 10%;
  //padding: 0 10%;
  box-sizing: border-box;

  @media (max-width: ${theme.responsive.mobile}) {
    margin: 0;
    padding: 0 5%;
    width: 100%;
  }
`;

export const Row = styled.div<{ hide?: boolean }>`
  display: ${(props) => (props.hide ? "none" : "flex")};
  justify-content: space-between;
  align-items: flex-end;

  &.base-section-header {
    margin: 1.3em 0;
    @media (max-width: ${theme.responsive.mobile}) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &.base-section-buttons {
    margin: 1em 0;
    justify-content: flex-end;

    @media (max-width: ${theme.responsive.mobile}) {
      justify-content: center;
      margin: 1.5em 0;
    }
  }
`;

export const ChildrenContainer = styled.div<{ mediaHeight: string; bg: string }>`
  display: flex;
  justify-content: center;
  height: ${(props) => props.mediaHeight};
  width: 100%;
  background: ${(props) => (props.bg ? `url(${props.bg})` : "transparent")};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 20px;
`;

export const FlexContainer = styled.div`
  display: flex;

  @media (max-width: ${theme.responsive.mobile}) {
    justify-content: left;
    width: 100%;
  }

  &.section-description-container {
    color: ${theme.color.text};
    flex-direction: column;
    justify-content: center;
    margin-left: 0.5em;
    font-family: ${theme.font.mainFont};

    @media (max-width: ${theme.responsive.mobile}) {
      margin: 0;
    }
  }
`;

export const SectionIconContainer = styled.div`
  width: 4em;
  height: 4em;
  @media (max-width: ${theme.responsive.mobile}) {
    display: none;
  }
`;

export const DurationText = styled.span`
  font-size: 0.8em;

  & > img {
    height: 0.8em;
    width: 0.8em;
  }

  @media (max-width: ${theme.responsive.mobile}) {
    display: none;
  }
`;

export const StyledParagraph = styled.p`
  margin: 0.2em;
  font-size: 1.1em;
`;
