import React from "react";
import { StandardButtonStyle } from "./StandardButton.style";

interface StandardButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  textButton: string;
}

const StandardButton: React.FC<StandardButtonProps> = (props) => {
  const { textButton, className, ...newProps } = props;
  return (
    <StandardButtonStyle className={`standard-btn ${className || ""}`} {...(newProps as any)}>
      {textButton}
    </StandardButtonStyle>
  );
};

export default StandardButton;
