import React from "react";

const IconGlasses = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 120 120" {...props}>
    <path
      fill="curentColor"
      d="M109.67,64.25,101.8,32.7A15.28,15.28,0,0,0,83.29,21.57c-.39.1-.77.21-1.15.34l-2.66.89a2.78,2.78,0,0,0-1.79,3.5h0L78.59,29a2.78,2.78,0,0,0,3.5,1.78h0L84.39,30a7.37,7.37,0,0,1,5.77.3,6.89,6.89,0,0,1,3.56,4.48l6.67,26.71a47.85,47.85,0,0,0-14.1-2.16,45.51,45.51,0,0,0-19.94,4.64H53.65a45.69,45.69,0,0,0-19.94-4.68,48.14,48.14,0,0,0-14.1,2.17l6.67-26.71a6.85,6.85,0,0,1,3.55-4.48,7.44,7.44,0,0,1,5.76-.3l2.28.75a2.77,2.77,0,0,0,3.5-1.77h0l.89-2.64a2.78,2.78,0,0,0-1.77-3.51h0l-2.67-.89a15.27,15.27,0,0,0-19.29,9.7,11.22,11.22,0,0,0-.33,1.13L10.33,64.26A10.93,10.93,0,0,0,10,66.94V79.13A19.9,19.9,0,0,0,30,98.91h6.44a19.91,19.91,0,0,0,20-18.29l.51-6.71h6.22l.51,6.71A19.91,19.91,0,0,0,83.56,98.91H90a19.89,19.89,0,0,0,20-19.78h0V66.94A11.07,11.07,0,0,0,109.67,64.25ZM45.31,79.78a8.83,8.83,0,0,1-8.87,8H30a8.8,8.8,0,0,1-8.89-8.66V72.62a37.61,37.61,0,0,1,12.6-2.22,34,34,0,0,1,12.14,2.22Zm53.58-.65A8.79,8.79,0,0,1,90,87.79H83.56a8.83,8.83,0,0,1-8.87-8l-.54-7.16a34,34,0,0,1,12.14-2.22,37.57,37.57,0,0,1,12.6,2.23Z"
    />
  </svg>
);

export default IconGlasses;
