import { styled } from "../../theme";

export const SuccessMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: ${(props) => props.theme.color.text};
`;
export const SuccessTitleContainer = styled.div`
  font-family: ${(props) => props.theme.font.styledFont};
  font-size: 2em;
  h2 {
    margin-bottom: 0.5em;
    font-weight: normal;
  }
`;

export const SuccessText = styled.div`
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  max-width: 70%;
  text-align: center;
`;

export const SuccessImageContainer = styled.div`
  .success-image {
    max-height: 40vh;
    @media (max-width: ${({ theme }) => theme.responsive.notebook}) {
      max-height: 35vh;
      margin: auto;
      max-width: 90%;
    }
  }
`;
