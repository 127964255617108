import React from "react";

const IconVideo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 383.03 383.88">
      <g fill={props.fill} id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
          <path d="M180.4,0h23.2c1.21.23,2.42.48,3.63.67,10.32,1.68,20.83,2.57,30.92,5.15C332.47,30,392.75,117.22,381.74,214.19c-5.55,48.88-27.06,90.24-64.62,121.91C261.83,382.71,198.45,396,130,372.52,59.76,348.44,18,297.72,3,225c-1.44-7-2-14.26-3-21.4V179.65c1-7,1.74-14.06,2.93-21C16.22,81,77.08,18.67,154.19,3.78,162.84,2.1,171.66,1.24,180.4,0Zm83.27,240c4.75,1.82,9.31,3.4,13.74,5.3,18.29,7.89,33.5-6.81,33.82-22.13.44-20.95.32-41.91,0-62.87-.17-12.65-10-23.48-22-24.26-4.39-.28-9,1.41-13.36,2.67-4.09,1.18-8,3-12.37,4.67,0-1.47-.06-2.21,0-2.95,1.29-14.84-9.62-29-28.85-28.81-44.9.4-89.82.12-134.73.14-17.32,0-28,10.71-28,28q0,51.65,0,103.3c0,18,10.53,28.53,28.61,28.54q35.75,0,71.48,0c21.46,0,42.92.06,64.38,0,14.24-.05,24.69-8.67,26.84-22.14A92.38,92.38,0,0,0,263.67,240Z" />
          <path d="M167.6,127.81h67.71c8.76,0,12.26,3.47,12.26,12.2q0,51.61,0,103.25c0,8.69-3.53,12.16-12.3,12.16h-135c-9,0-12.45-3.42-12.45-12.4q0-51.43,0-102.88c0-8.95,3.44-12.34,12.49-12.34Z" />
          <path d="M295.49,191.62c0,9.35.08,18.7-.06,28a18.37,18.37,0,0,1-1.31,6.87c-2,4.54-5.44,5.81-10.11,4.08-6.06-2.26-12.08-4.66-18.16-6.88a3.13,3.13,0,0,1-2.41-3.49q.12-28.62,0-57.23a3.14,3.14,0,0,1,2.4-3.51c6-2.17,11.86-4.53,17.8-6.76,5.45-2.05,9.64,0,11.21,5.64a21,21,0,0,1,.61,5.55C295.52,173.16,295.49,182.39,295.49,191.62Z" />
        </g>
      </g>
    </svg>
  );
};

export default IconVideo;
