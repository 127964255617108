import React from "react";

export const spanify = (stringBlock: string, customItemClassname?: string) => {
  const stringArray = stringBlock.split("///");
  return stringArray.map((item, index) => (
    <span key={index} className={customItemClassname}>
      {item}
    </span>
  ));
};
