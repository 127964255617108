import { filter } from "lodash";
import React, { useState, ChangeEvent, useRef } from "react";
import { Video } from "./VideoPlayer.style";

interface Props {
  thumbnail?: string;
  video: string;
  subtitle?: string;
  onProgressMeasureChange?: (progressInPercents: number) => void;
  onDurationChange?: (durationInSecs: number) => void;
  onPlay?: () => void;
  onPause?: () => void;
  autoplay?: boolean;
}
const getVideoType = (url: string) => {
  const types = [
    {
      name: "mp4",
      regex: "/*.mp4",
    },
    {
      name: "webm",
      regex: "/*.webm",
    },
    {
      name: "ogg",
      regex: "/*.ogv",
    },
  ];
  for (let i = 0, n = types.length; i < n; i++) {
    if (RegExp(types[i].regex).test(url)) return types[i].name;
  }

  return "";
};

const VideoPlayer: React.FC<Props> = (props) => {
  const [duration, setDuration] = useState(-1);
  const [isPlaying, setIsPlaying] = useState(props.autoplay || false);
  const ref = useRef<HTMLVideoElement>(null);

  const onDurationChange = (event: ChangeEvent<HTMLVideoElement>) => {
    setDuration(event.target.duration);
    props.onDurationChange && props.onDurationChange(event.target.duration);
  };

  const onTimeUpdate = (event: ChangeEvent<HTMLVideoElement>) => {
    const progressInPercent = Math.round((event.target.currentTime / duration) * 100);
    props.onProgressMeasureChange && props.onProgressMeasureChange(progressInPercent);
  };

  const onPlay = () => {
    setIsPlaying(true);
    props.onPlay && props.onPlay();
  };

  const onPause = (event: ChangeEvent<HTMLVideoElement>) => {
    if (event.target.seeking) return;

    props.onPause && props.onPause();
  };

  return (
    <React.Fragment>
      <Video
        ref={ref}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={() => setIsPlaying(false)}
        onDurationChange={onDurationChange}
        onTimeUpdate={onTimeUpdate}
        style={{ display: isPlaying ? "initial" : "none" }}
        controls
      >
        <source src={props.video} type={`video/${getVideoType(props.video)}`} />
        {props.subtitle && <track src={props.subtitle} default kind="captions" />}
      </Video>
      {!isPlaying && (
        <button className="video-play-button no-style-btn" onClick={() => !isPlaying && ref?.current?.play()}>
          <img style={{ width: "12vw" }} src="./img/video/play.svg" />
        </button>
      )}
    </React.Fragment>
  );
};

export default VideoPlayer;
