import { styled } from "../../theme";

export const MainContainer = styled.div<BackgroundProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.landingPageBackgroundColor};
  position: relative;
  height: 94vh;
  justify-content: space-around;
  box-sizing: border-box;

  &::before {
    background-image: ${(props) => `url(${props.desktop})`};
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
    content: "";
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @media (max-width: ${(props) => props.theme.responsive.mobile}) {
      background-image: ${(props) => `url(${props.mobile ? props.mobile : props.desktop})`};
    }
  }
`;
interface LogoProps {
  logo?: string;
}
interface BackgroundProps {
  desktop: string;
  mobile?: string;
}

export const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: min(1200px, 80%);
  margin: auto;
  min-height: 100%;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  z-index: 2;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    width: 100%;
  }
`;

export const LogoLandingPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 3rem 0;

  @media (max-width: ${(props) => props.theme.responsive.notebook}) {
    margin: 2rem 0;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    img {
      width: 100%;
    }
  }
`;

interface LandingPageProps {
  image: string;
  imageMobile: string;
}

export const LandingPageImageContainer = styled.div`
  width: 100%;
  min-height: 20em;
  align-self: center;
  @media (max-width: ${(props) => props.theme.responsive.notebook}) {
    min-height: 12em;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    width: 100%;
    min-height: 9em;
  }
`;

export const Illustration = styled.div<LandingPageProps>`
  background-image: ${(props) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100%;
  width: 100%;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    background-image: ${(props) => `url(${props.imageMobile})`};
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 3rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    flex-direction: column;
    width: 90%;
  }
  @media (max-width: ${(props) => props.theme.responsive.notebook}) {
    margin-bottom: 2rem;
  }
`;

export const TitleContainer = styled.div`
  width: 70%;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    width: 100%;
    max-width: inherit;
  }
`;

export const Title = styled.h1`
  margin-top: inherit;
  margin-bottom: inherit;
  font-family: ${(props) => props.theme.font.styledFont};
  color: ${(props) => props.theme.color.mainTitleColor};
  text-align: center;
  padding: 0.1em;
  font-size: 4em;
  //font-weight: bolder;
  width: auto;
  //white-space: nowrap;
  margin-top: 0;
  font-weight: normal;
  span {
    line-height: 0.8em;
    display: block;
    @media (max-width: ${(props) => props.theme.responsive.mobile}) {
      display: initial;
      &:not(:last-of-type)::after {
        content: " ";
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.notebook}) {
    font-size: 3.6em;
  }

  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    font-size: 2.8em;
    margin-top: 0;
    white-space: inherit;
  }
`;

export const DashedLineSeparator = styled.div`
  border-bottom: dashed white 0.4em;
  color: white;
  align-self: center;
  width: 70%;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    width: 90%;
  }
`;

export const Subtitle = styled.div`
  width: 100%;
  min-width: 4em;
  color: white;
  text-align: center;
  padding: 0.5em;
  font-size: 2.4em;

  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    font-size: 1.5em;
    white-space: wrap;
    max-height: 3em;
  }
`;

export const Description = styled.div`
  color: ${(props) => props.theme.color.text};
  margin-bottom: 3rem;
  max-width: 50%;
  font-size: 1.3em;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    max-width: 90%;
  }
  @media (max-width: ${(props) => props.theme.responsive.notebook}) {
    font-size: 1.1em;
    margin-bottom: 2rem;
  }
`;

export const TimePill = styled.div`
  font-family: ${(props) => props.theme.font.mainFont};
  margin-left: 0;
  color: #fff;
  padding: 0.1em;
  width: 5em;
  height: 5em;
  background-color: ${(props) => props.theme.color.timePillColor};
  border-radius: 3em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.24);
  border: solid #fff 0.1em;
  @media (max-width: ${(props) => props.theme.responsive.mobile}) {
    display: none;
  }
`;

export const TimeTitle = styled.span`
  font-weight: bold;
  font-size: 1.7em;
  vertical-align: text-bottom;
  display: inline-block;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  vertical-align: center;
  padding: 1em;
  height: 10%;
  margin-bottom: 1em;
`;
