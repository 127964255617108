import { styled } from "../../theme";

export interface ButtonProps {
  noHover?: boolean;
}
export const ButtonPill = styled.button<ButtonProps>`
  position: relative;
  overflow: hidden;
  border-radius: 1em;
  border-width: 0;
  height: 2em;
  background-color: ${props => props.theme.color.mainColor};
  color: #fff;
  font-size: large;
  box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: large;
  transition: all 0.2s ease-in-out;
  padding-left: 1em;
  padding-right: 1em;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  &:hover {
    filter: brightness(90%);
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 20%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }
  &:active:after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }
`;
