import styled from "styled-components";

//TODO: add responsive behaviour
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IllustrationContainer = styled.div`
  width: 845px;
  height: 475px;
`;

export const Picture = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const Content = styled.div`
  background-color: #fff;
  margin: 0 0 0 -100px;
  width: 450px;
  padding: 40px 55px 40px 54px;
`;
