import { styled } from "../../theme";

interface SwitchProps {
  checked: boolean;
}
export const SwitchContainer = styled.div<SwitchProps>`
  position: relative;
  width: 5em;
  height: 2em;
  border-radius: 1.1em;
  background-color: ${props =>
    props.checked ? props.theme.color.mainColor : props.theme.color.text};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-style: solid;

  border-width: 0.2em;
  border-color: ${props =>
    props.checked ? props.theme.color.mainColor : props.theme.color.text};
`;

export const SwitchKnobs = styled.div<SwitchProps>`
  z-index: 2;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: ${props => (props.checked ? "100%" : 0)};
  margin-left: ${props => (props.checked ? "-2em" : 0)};
  top: auto;
  bottom: auto;
  background-color: #fff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
`;
