import { styled } from "../../theme";

export const SecondaryResourceContainer = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  cursor: pointer;
  border-bottom: solid;
  border-width: 2px;
  border-color: ${props => props.theme.color.text};
  padding: 1em 2em 1em 2em;
`;

export const SecondaryResourceTextContainer = styled.div`
  margin-left: 1em;
`;

export const SecondaryResourceTitle = styled.div`
  font-weight: bold;
  font-size: large;
  color: ${props => props.theme.color.text};
`;

export const SecondaryResourceDescription = styled.div`
  color: ${props => props.theme.color.text};
`;
