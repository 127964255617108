import { styled } from "./theme";

export const AppStyle = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: normal;
  font-style: normal;
  @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    height: inherit;
  }
`;

export const MainContainer = styled.main`
  box-sizing: border-box;
  box-shadow: 0em 0.3em 12px -1px rgba(0, 0, 0, 0.54) inset;
`;
