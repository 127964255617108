import React, { useContext } from "react";

import IconVideo from "./video";
import IconQuiz from "./IconQuiz";
import IconValid from "./IconValid";
import IconInvalid from "./IconInvalid";
import IconReplay from "./IconReplay";
import IconCancel from "./IconCancel";
import IconHome from "./IconHome";
import IconChevron from "./IconChevron";
import IconLink from "./IconLink";
import IconTimer from "./IconTimer";
import IconGlasses from "./IconGlasses";
import IconPodcast from "./podcast";
import IconPdf from "./pdf";
import IconInfo from "./info";
import IconResources from "./resources";
import IconCaseStudy from "./caseStudy";
import { ThemeContext } from "styled-components";
import { Theme } from "../theme";

interface Props extends React.SVGProps<SVGSVGElement> {
  name:
    | "video"
    | "podcast"
    | "info"
    | "pdf"
    | "resource"
    | "caseStudy"
    | "valid"
    | "invalid"
    | "replay"
    | "cancel"
    | "home"
    | "chevron"
    | "link"
    | "timer"
    | "glasses"
    | "quiz";
}
const Icon: React.FC<Props> = (props) => {
  const theme = useContext<Theme>(ThemeContext);

  const extendedProps: Props = {
    fill: theme.color.sectionIconColor,
    ...props,
  };

  switch (props.name) {
    case "caseStudy":
      return <IconCaseStudy {...extendedProps} />;
    case "resource":
      return <IconResources {...extendedProps} />;
    case "info":
      return <IconInfo {...extendedProps} />;
    case "pdf":
      return <IconPdf {...extendedProps} />;
    case "video":
      return <IconVideo {...extendedProps} />;
    case "podcast":
      return <IconPodcast {...extendedProps} />;
    case "quiz":
      return <IconQuiz {...extendedProps} />;
    case "valid":
      return <IconValid {...extendedProps} />;
    case "invalid":
      return <IconInvalid {...extendedProps} />;
    case "replay":
      return <IconReplay {...extendedProps} />;
    case "cancel":
      return <IconCancel {...extendedProps} />;
    case "home":
      return <IconHome {...extendedProps} />;
    case "chevron":
      return <IconChevron {...extendedProps} />;
    case "link":
      return <IconLink {...extendedProps} />;
    case "timer":
      return <IconTimer {...extendedProps} />;
    case "glasses":
      return <IconGlasses {...extendedProps} />;
  }
};

export default Icon;
