import React, { useState } from "react";
import { FooterContainer, FooterLink, FooterItemContent, ContainerInModal, FooterItemContainer } from "./Footer.style";
import { FooterItem, FooterContent, GoogleAnalyticsParams } from "../../types";
import ReactModal from "react-modal";
import Icon from "../../icons";
import { flatten } from "lodash";
import { Debug } from "..";
import CookieConsent, { resetCookieConsentValue } from "react-cookie-consent";
import { ItemAnwer } from "../ResultCorrectionItem/ResultCorrectionItem.style";

interface Props {
  itemList: FooterItem[];
  cookieCompliance?: GoogleAnalyticsParams;
}

const Footer: React.FC<Props> = (props) => {
  const [modalContent, setModalContent] = useState<string | undefined>();
  const [showCookieCompliance, displayCookieCompliance] = useState(false);
  const onClickContentItem = (item: FooterContent) => () => {
    setModalContent(item.content);
  };

  return (
    <>
      <div style={{ flexGrow: 1 }}></div>
      <FooterContainer className="footer-container">
        <Debug />
        {props.itemList.map((item) => {
          if (item.type === "link")
            return (
              <FooterItemContainer>
                {" "}
                <FooterLink className="footer_lien" href={item.link} target="_blank">
                  {item.text}
                </FooterLink>
              </FooterItemContainer>
            );
          else
            return (
              <FooterItemContainer>
                <FooterItemContent className="footer_contenu" onClick={onClickContentItem(item)}>
                  {item.text}
                </FooterItemContent>
              </FooterItemContainer>
            );
        })}
        <ReactModal
          style={{
            overlay: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            content: {
              position: "unset",
              maxWidth: "70vw",
              maxHeight: "90vh",
            },
          }}
          isOpen={modalContent !== undefined}
          onRequestClose={() => setModalContent(undefined)}
        >
          <ContainerInModal>
            <Icon
              name="cancel"
              style={{ alignSelf: "flex-end", cursor: "pointer" }}
              fontSize={"2em"}
              onClick={() => setModalContent(undefined)}
            />
            <div
              style={{ paddingRight: "3em", paddingLeft: "3em" }}
              dangerouslySetInnerHTML={{ __html: modalContent || "" }}
            ></div>
          </ContainerInModal>
        </ReactModal>
        {props.cookieCompliance && (
          <React.Fragment>
            <CookieConsent
              buttonText={props.cookieCompliance.cookieAcceptButtonText}
              enableDeclineButton
              visible={showCookieCompliance ? "show" : "hidden"}
              declineButtonText={props.cookieCompliance.cookieDeclineButtonText}
              onAccept={() => displayCookieCompliance(false)}
              onDecline={() => displayCookieCompliance(false)}
              overlay
              style={{
                background: "black",
                color: "white",
              }}
              buttonStyle={{
                background: "green",
                color: "white",
                fontWeight: "bolder",
              }}
              declineButtonStyle={{ background: "red", color: "white" }}
            >
              <h1>{props.cookieCompliance.cookieComplianceTitle}</h1>
              <p>{props.cookieCompliance.cookieComplianceText}</p>
            </CookieConsent>
            <FooterItemContainer>
              <FooterItemContent
                className="footer_contenu cookie-compliance-reset"
                onClick={() => displayCookieCompliance(!showCookieCompliance)}
              >
                {"À propos des cookies"}
              </FooterItemContent>
            </FooterItemContainer>
          </React.Fragment>
        )}
      </FooterContainer>
    </>
  );
};

export default Footer;
