import { styled } from "../../theme";

export const SectionVideoDrawerContainer = styled.div`
  padding: 1em;
  width: 100%;
  max-height: 90vh;
  background: #fff;
  display: flex;
  justify-content: center;
`;
export const SectionVideoDrawerContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
`;
export const SectionVideoDrawerHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 20em;
`;

export const SectionVideoDrawerIndex = styled.div`
  font-size: 10em;
  color: ${props => props.theme.color.lightText};
  font-weight: bolder;
  line-height: 1em;
`;

export const SectionVideoDrawerTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionVideoDrawerTitle = styled.h2`
  color: ${props => props.theme.color.mainColor};
  hyphens: auto;
`;

export const SectionVideoDrawerSubtitle = styled.div`
  font-size: small;
  color: ${props => props.theme.color.text};
`;

export const VideoContainer = styled.div`
  width: 50em;
  max-width: 90%;
  height: auto;
  position: relative;
`;