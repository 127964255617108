import React, { useState, useEffect } from "react";
import Store from "../../state/store";
import Actions from "../../state/actions";
import { useHistory } from "react-router-dom";
import { findItemAndIndexById } from "@upandgo/react-quiz";
import { inject, observer } from "mobx-react";
import { Section } from "../../types";
import { useTranslation } from "react-i18next";
import { getCookieConsentValue } from "react-cookie-consent";
import ReactGA from "react-ga";
import { useMatomoTracking } from "../../hooks";

interface Props {
  idSection: string;
  store?: Store;
  mutator?: Actions;
  Component: React.FC<any>;
}

export interface EnrichedProps {
  onNext: () => void;
  onPrevious: () => void;
  completeSection: () => Promise<void>;
  getNextButtonName: () => string;
  content: Section;
  isLast: boolean;
  isSectionCompleted: boolean;
  sectionCount: number;
  currentIndex: number;
}

const SectionWrapper: React.FC<Props> = (props) => {
  //TODO: ajout dans types des fonctions étendues pour les héritiers de base component
  const history = useHistory();
  const { t } = useTranslation();

  const { content } = props.store!;
  const { idSection, Component, ...passthroughProps } = props;
  const { item: sectionContent, index } = findItemAndIndexById(props.idSection, content.sections);
  useMatomoTracking({ documentTitle: sectionContent.title.length > 0 ? sectionContent.title : sectionContent.id });
  const [isSectionCompleted, setIsSessionCompleted] = useState(
    props.store?.completedSectionIds?.includes(sectionContent.id) || false,
  );

  useEffect(() => {
    const cookieAcceptance = getCookieConsentValue() === "true";
    if (content.googleAnalyticsParams && cookieAcceptance) {
      ReactGA.pageview(`/section/${idSection}`);
    }
  }, [idSection]);
  const onNext = () => {
    if (content.sections[index + 1]) {
      history.push(`/section/${content.sections[index + 1].id}`);
    } else {
      history.push(`/section/${content.sections[0].id}`);
    }
  };

  const onPrevious = () => {
    if (content.sections[index - 1]) {
      history.push(`/section/${content.sections[index - 1].id}`);
    } else {
      history.push(`/section/${content.sections[0].id}`);
    }
  };
  const nextSection = content.sections[index + 1];
  const getNextButtonName = (useNextSectionTitle?: boolean) => {
    if (!isLast) return useNextSectionTitle ? nextSection.title : t("common:next");
    else return t("common:backToWelcomePage");
  };
  const isLast = index === content.sections.length - 1;
  const completeSection = async () => {
    if (isSectionCompleted) return;

    const progressIncreaseInPercent = content.sections[index].progressIncreaseOnComplete || 0;
    await props.mutator?.updateSavedSection(content.sections[index].id, { done: true });
    await props.mutator?.incrementProgressMeasureBy(progressIncreaseInPercent);
    await props.mutator?.computeGlobalCompleteStatus();
    setIsSessionCompleted(true);
  };

  return (
    <Component
      isLast={isLast}
      onNext={onNext}
      onPrevious={onPrevious}
      content={sectionContent}
      completeSection={completeSection}
      getNextButtonName={getNextButtonName}
      isSectionCompleted={isSectionCompleted}
      idSection={idSection}
      sectionCount={content.sections.length}
      currentIndex={index}
      {...passthroughProps}
    />
  );
};

export default inject("store", "mutator")(observer(SectionWrapper));
